define("building-management-dashboard/pods/components/bill/alert-delete/component", ["exports", "sweetalert2"], function (_exports, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      async preConfirm(forceDelete) {
        const adapterOptions = {
          force: forceDelete === 1
        };

        try {
          await this.bill.destroyRecord({
            adapterOptions
          });

          if (Ember.isPresent(this.onDeleted)) {
            this.onDeleted(this.bill);
          }
        } catch ({
          message
        }) {
          _sweetalert.default.showValidationMessage(message);

          this.bill.rollbackAttributes();
        }
      }

    }
  });

  _exports.default = _default;
});