define("building-management-dashboard/pods/components/oneui/oneui-layout/header/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['content-mini', 'content-mini-full'],
    elementId: 'header-navbar',
    tagName: 'header',
    actions: {
      toggleSidebar(toggleMode) {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const pageContainer = (0, _jquery.default)('#page-container');

        switch (toggleMode) {
          case 'sidebar_toggle':
            if (width > 991) {
              pageContainer.toggleClass('sidebar-o');
            } else {
              pageContainer.toggleClass('sidebar-o-xs');
            }

            break;

          case 'sidebar_mini_toggle':
            if (width > 991) {
              pageContainer.toggleClass('sidebar-mini');
            }

            break;
        }
      }

    }
  });

  _exports.default = _default;
});