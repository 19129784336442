define("building-management-dashboard/pods/components/oneui/select/properties/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    properties: Ember.computed('building.id', function () {
      const building = this.get('building');

      if (Ember.isNone(building)) {
        return [];
      }

      return this.store.query('property', {
        building: building.get('id'),
        limit: false,
        orderBy: 'name'
      });
    })
  });

  _exports.default = _default;
});