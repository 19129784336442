define("building-management-dashboard/pods/components/coa/modal-entry/component", ["exports", "building-management-dashboard/pods/components/coa/modal-entry/validation"], function (_exports, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    Validation: _validation.default,
    protected: Ember.computed.alias('chartOfAccount.protected'),
    except: Ember.computed('chartOfAccount.id', function () {
      const id = this.get('chartOfAccount.id');

      if (Ember.isPresent(id)) {
        return [id];
      }

      return null;
    }),
    types: Ember.computed('chartOfAccount.type', function () {
      const type = this.get('chartOfAccount.type');

      if (Ember.isPresent(type)) {
        return [type];
      }

      return ['asset', 'expense'];
    }),
    actions: {
      async save(account) {
        this.set('error', null);
        const {
          onSaved
        } = this.getProperties('onSaved');

        try {
          await account.save();
          this.set('open', false);

          if (Ember.isPresent(onSaved)) {
            onSaved(account);
          }
        } catch (err) {
          this.set('error', err);
        }
      }

    }
  });

  _exports.default = _default;
});