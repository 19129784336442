define("building-management-dashboard/models/chart-of-account", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    code: attr('string'),
    description: attr('string'),
    name: attr('string'),
    protected: attr('boolean'),
    type: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    building: belongsTo(),
    children: hasMany('chart-of-account', {
      inverse: 'parent'
    }),
    parent: belongsTo('chart-of-account', {
      inverse: 'children'
    })
  });

  _exports.default = _default;
});