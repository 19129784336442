define("building-management-dashboard/pods/components/oneui/panel/data-table-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hkz7Kx2P",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"div\",true],[13,\"class\",[32,[\"block-header bg-\",[27,[26,4,\"AppendSingleId\"],[]]]],null],[10],[1,1,0,0,\"\\n  \"],[9,\"ul\",true],[12,\"class\",\"block-options\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,6,\"BlockHead\"],[]],[[27,[26,5,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"      \"],[9,\"li\",true],[10],[1,1,0,0,\"\\n        \"],[9,\"button\",false],[23,\"type\",\"button\",null],[3,0,0,[27,[26,2,\"ModifierHead\"],[]],[[27,[24,0],[]],[31,138,8,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null]],null],[10],[1,1,0,0,\"\\n          \"],[1,0,0,0,[31,169,7,[27,[26,3,\"CallHead\"],[]],[\"plus\"],null]],[1,1,0,0,\"\\n        \"],[11],[1,1,0,0,\"\\n      \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"  \"],[11],[1,1,0,0,\"\\n\\n  \"],[9,\"h3\",true],[12,\"class\",\"block-title\",null],[10],[1,0,0,0,[27,[26,7,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"block-content\",null],[10],[1,1,0,0,\"\\n  \"],[16,1,null],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"onAdd\",\"optional\",\"action\",\"fa-icon\",\"type\",\"canAdd\",\"if\",\"title\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/panel/data-table-container/template.hbs"
    }
  });

  _exports.default = _default;
});