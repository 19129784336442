define("building-management-dashboard/pods/components/oneui/page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HbgdOeXJ",
    "block": "{\"symbols\":[\"@subHeader\",\"@header\",\"&default\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"content bg-gray-lighter\",null],[10],[9,\"div\",true],[12,\"class\",\"row items-push\",null],[10],[9,\"div\",true],[12,\"class\",\"col-sm-7\",null],[10],[9,\"h1\",true],[12,\"class\",\"page-heading\",null],[10],[1,0,0,0,[27,[24,2],[]]],[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,1],[]]],null,[[\"default\"],[{\"statements\":[[9,\"small\",true],[12,\"class\",\"push-5-l\",null],[10],[1,0,0,0,[27,[24,1],[]]],[11]],\"parameters\":[]}]]],[11],[11],[9,\"div\",true],[12,\"class\",\"col-sm-5 text-right hidden-xs\",null],[10],[1,0,0,0,[27,[26,1,\"AppendSingleId\"],[]]],[11],[11],[11],[9,\"div\",true],[12,\"class\",\"content\",null],[10],[16,3,null],[11]],\"hasEval\":false,\"upvars\":[\"if\",\"bread-crumbs\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/page/template.hbs"
    }
  });

  _exports.default = _default;
});