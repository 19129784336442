define("building-management-dashboard/translations/nl-nl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "alert": {
      "attachment": {
        "summary": "{count, plural,\n  =0 {bijlagen}\n  other {({count}) bijlagen}\n}\n"
      },
      "bill": {
        "delete": "Deze rekening verwijderen, {name}?",
        "force-delete": "Gedwongen verwijderen slaat de transactiebeperkingen over.",
        "pay": "Betaal {amount, number, EUR}"
      },
      "building": {
        "delete": "Dit gebouw verwijderen, {name}?"
      },
      "coa": {
        "delete": "Dit account verwijderen, {name}?"
      },
      "contact": {
        "delete": "Dit contact verwijderen, {name}?"
      },
      "document": {
        "delete": "Delete this document, {name}?"
      },
      "drop-upload": "Zet neer om te uploaden",
      "invalid": "Ongeldig",
      "loading": "Even geduld aub...",
      "login": {
        "welcome": "Welkom, graag inloggen."
      },
      "n-items": "{count} items",
      "no-matches": "Geen resultaten gevonden.",
      "no-records": "Geen records geretourneerd.",
      "please-confirm": "Veuillez confirmer",
      "project": {
        "delete": "Dit project verwijderen, {name}?"
      },
      "property": {
        "delete": "Deze property verwijderen, {name}?"
      },
      "property-group": {
        "delete": "Deze propertygroep, {name}, verwijderen?"
      },
      "shares": "Aandelen: {shares}",
      "showing-records": "Tonen van {start} - {end} van {total} {resource}",
      "supplier": {
        "delete": "Deze leverancier verwijderen, {name}?"
      },
      "total-shares": "Totaal aandelen: {shares}",
      "upload-files": "{count} bestanden uploaden. ({progress}%)",
      "user": {
        "delete": "Deze gebruiker verwijderen, {name}?"
      }
    },
    "button": {
      "add": "Toevoegen",
      "add-attachment": "Voeg bijlage toe",
      "cancel": "Annuleren",
      "close": "Dichtbij",
      "delete": "Delete",
      "edit": "Bewerk",
      "export-pdf": "PDF exporteren",
      "filter": "Filter",
      "force-delete": "Forceer verwijderen",
      "mark-paid": "Mark betaald",
      "ok": "OK",
      "save": "Opslaan",
      "upload": "Uploaden",
      "view": "Visie"
    },
    "header": {
      "bill": {
        "create": "Nieuwe factuur toevoegen",
        "page": "Factuurbeheer",
        "pay": "Betaling van facturen"
      },
      "building": {
        "create": "Nieuw gebouw toevoegen",
        "edit": "Gebouw {name} bewerken",
        "page": "Gebouwenbeheer"
      },
      "coa": {
        "create": "Nieuw account toevoegen",
        "edit": "Account {name} bewerken",
        "page": "Accountbeheer"
      },
      "contact": {
        "create": "Voeg nieuw contact toe",
        "edit": "Bewerk contact {name}",
        "page": "Contactenbeheer"
      },
      "document": {
        "create": "Nieuwe map toevoegen",
        "page": "Documentenbeheer"
      },
      "invoice": {
        "page": "Facturenbeheer"
      },
      "login": {
        "page": "Log in"
      },
      "project": {
        "create": "Nieuw project toevoegen",
        "edit": "Project bewerken {name}",
        "page": "Project management"
      },
      "property": {
        "create": "Nieuwe eigenschap toevoegen",
        "edit": "Property {name} bewerken",
        "page": "Beheer van eigenschappen"
      },
      "property-group": {
        "create": "Nieuwe eigenschap toevoegen",
        "page": "Beheer van eigendomsgroepen"
      },
      "supplier": {
        "create": "Nieuwe leverancier toevoegen",
        "edit": "Bewerk leverancier {name}",
        "page": "Leveranciersmanagement"
      },
      "transaction": {
        "page": "Beheer van transacties"
      },
      "user": {
        "create": "Voeg een nieuwe gebruiker toe",
        "edit": "Gebruiker {name} bewerken",
        "page": "Gebruikersbeheer"
      }
    },
    "model": {
      "account": "{count, plural, one {account} other {rekeningen}}\n",
      "administrator": "{count, plural, one {beheerder} other {administrators}}\n",
      "attachment": "{count, plural, one {hechting} other {bijlagen}}\n",
      "bill": "{count, plural, one {bill} other {biljetten}}\n",
      "building": "{count, plural, one {gebouw} other {gebouwen}}\n",
      "contact": "{count, plural, one {contact} other {contacten}}\n",
      "document": "{count, plural, =1 {document} other {documenten}}\n",
      "invoice": "{count, plural, one {factuur} other {facturen}}\n",
      "project": "{count, plural, one {project} other {projecten}}\n",
      "property": "{count, plural, one {eigendom} other {eigenschappen}}\n",
      "property-bill": "{count, plural, one {eigendomsfactuur} other {eigendomsrekeningen}}\n",
      "property-group": "{count, plural, one {eigendomsgroep} other {eigenschap groepen}}\n",
      "role": "{count, plural, one {rol} other {rollen}}\n",
      "supplier": "{count, plural, one {leverancier} other {leveranciers}}\n",
      "transaction": "{count, plural, one {transactie} other {transacties}}\n",
      "user": "{count, plural, one {gebruiker} other {users}}\n"
    },
    "placeholder": {
      "dropzone": "Sleep bestanden om ze te uploaden",
      "force-delete": "Verwijderen forceren",
      "select-account": "Selecteer een account",
      "select-bill-type": "Selecteer factuurtype",
      "select-contact": "Selecteer een contactpersoon",
      "select-country": "Selecteer een land",
      "select-project": "Selecteer een project",
      "select-properties": "Selecteer eigenschappen",
      "select-property": "Selecteer een woning",
      "select-roles": "Selecteer rollen",
      "select-supplier": "Selecteer een leverancier",
      "select-type": "Selecteer een type"
    },
    "title": {
      "actions": "Acties",
      "address": "Adres",
      "admin": "Beheerder",
      "amount": "Bedrag",
      "app": "BM-dashboard",
      "asset": "Bedrijfsmiddel",
      "balance": "Balans",
      "building": "Gebouw",
      "capital": "Hoofdstad",
      "code": "Code",
      "contact-number": "Contact nummer",
      "contact-person": "Contactpersoon",
      "country": "Land",
      "credit": "Credit",
      "currency": "Valuta",
      "date": "Datum",
      "debit": "Debiteren",
      "description": "Beschrijving",
      "download": "Downloaden",
      "email": "E-mail",
      "expense": "Kosten",
      "first-name": "Voornaam",
      "forgot-password": "Wachtwoord vergeten?",
      "from": "Van",
      "income": "Inkomen",
      "languages": "Talen",
      "last-modified": "Laatst gewijzigd",
      "last-name": "Achternaam",
      "limit": "Limiet",
      "login": "Log in",
      "logout": "Uitloggen",
      "name": "Naam",
      "owner": "Eigenaar",
      "paid": "Betaald",
      "parent": "Ouder",
      "password": "Wachtwoord",
      "payment": "Betaling",
      "pending": "In afwachting",
      "postal-code": "Postcode",
      "project": "Project",
      "property-group": "Eigendom groep",
      "reference": "Referentienummer",
      "root": "Root",
      "shares": "Aandelen",
      "signup": "Inschrijven",
      "size": "Grootte",
      "state": "Staat",
      "supplier": "Leverancier",
      "tax-number": "Btw nummer",
      "title": "Titel",
      "to": "Naar",
      "total-amount": "Totaalbedrag",
      "total-due": "Totaal verschuldigd",
      "total-paid": "Totaal betaald",
      "total-shares": "Totaal aandelen",
      "type": "Type",
      "username": "Gebruikersnaam",
      "website": "Website"
    },
    "validations": {
      "email": "{description} moet een geldig e-mailadres zijn",
      "greaterThan": "{description} moet groter zijn dan {gt}",
      "phone": "{description} moet een geldig telefoonnummer zijn",
      "present": "{description} mag niet leeg zijn",
      "tooLong": "{description} is te lang (maximum is {max} tekens)",
      "url": "{description} moet een geldige URL zijn"
    }
  };
  _exports.default = _default;
});