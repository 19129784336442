define("building-management-dashboard/pods/dashboard/my-building/contacts/contact/transactions/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      getTransactions(propertyContract) {
        const bankAcctId = propertyContract.belongsTo('bankAccount').id();
        const transactions = this.get('store').query('transaction', {
          bankAccounts: [bankAcctId]
        });
        transactions.then(transactions => {
          propertyContract.set('hasTransactions', !!transactions.get('length'));
        });
        return transactions;
      }

    }
  });

  _exports.default = _default;
});