define("building-management-dashboard/pods/components/admin-dashboard/page-footer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'footer',
    classNames: ['content-mini', 'content-mini-full', 'font-s12', 'bg-gray-lighter', 'clearfix']
  });

  _exports.default = _default;
});