define("building-management-dashboard/pods/components/oneui/oneui-layout/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    elementId: 'page-container',
    classNames: ['sidebar-l', 'sidebar-o', 'side-scroll', 'header-navbar-fixed']
  });

  _exports.default = _default;
});