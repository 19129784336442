define("building-management-dashboard/pods/components/coa/table-data/child-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['animated', 'fadeIn'],
    tagName: 'tbody'
  });

  _exports.default = _default;
});