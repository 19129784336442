define("building-management-dashboard/pods/components/oneui/main-dashboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZeVrWWGG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[31,11,4,[27,[26,3,\"CallHead\"],[]],null,[[\"sidebar\",\"header\",\"main\",\"footer\"],[[31,29,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/main-dashboard/page-sidebar\"],null],[31,88,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/main-dashboard/page-header\"],[[\"user\",\"onLogout\"],[[27,[26,2,\"Expression\"],[]],[27,[26,1,\"Expression\"],[]]]]],[31,172,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/main-dashboard/page-main\"],null],[31,228,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/main-dashboard/page-footer\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"onLogout\",\"user\",\"hash\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/main-dashboard/template.hbs"
    }
  });

  _exports.default = _default;
});