define("building-management-dashboard/pods/components/oneui/pagination/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Cp3/+aW7",
    "block": "{\"symbols\":[\"num\",\"@label\",\"@onPageChange\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"col-sm-6\",null],[10],[5,[27,[26,3,\"BlockHead\"],[]],[[27,[24,0],[\"total\"]]],null,[[\"default\"],[{\"statements\":[[9,\"div\",true],[12,\"class\",\"data-table-info\",null],[10],[1,0,0,0,[31,71,1,[27,[26,0,\"CallHead\"],[]],[\"title.limit\"],null]],[5,[27,[26,2,\"BlockHead\"],[]],null,[[\"options\",\"searchField\",\"selected\",\"onChange\"],[[27,[24,0],[\"limits\"]],\"label\",[27,[24,0],[\"limit\"]],[31,174,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"selectLimit\"],null]]],[[\"default\"],[{\"statements\":[[1,0,0,0,[27,[24,1],[\"label\"]]]],\"parameters\":[1]}]]],[5,[27,[26,3,\"BlockHead\"],[]],[[27,[24,0],[\"showText\"]]],null,[[\"default\"],[{\"statements\":[[1,0,0,0,[31,259,1,[27,[26,0,\"CallHead\"],[]],[\"alert.showing-records\"],[[\"start\",\"end\",\"total\",\"resource\"],[[27,[24,0],[\"start\"]],[27,[24,0],[\"end\"]],[27,[24,0],[\"total\"]],[27,[24,2],[]]]]]]],\"parameters\":[]}]]],[11]],\"parameters\":[]}]]],[11],[9,\"div\",true],[12,\"class\",\"col-sm-6\",null],[10],[1,0,0,0,[31,399,16,[27,[26,5,\"CallHead\"],[]],null,[[\"current\",\"count\",\"change\",\"class\"],[[27,[24,0],[\"currentPage\"]],[27,[24,0],[\"totalPage\"]],[31,470,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],[31,478,8,[27,[26,4,\"CallHead\"],[]],[[27,[24,3],[]]],null]],null],\"pull-right\"]]]],[11]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"power-select\",\"if\",\"optional\",\"pagination-pager\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/pagination/template.hbs"
    }
  });

  _exports.default = _default;
});