define("building-management-dashboard/pods/components/oneui/main-dashboard/page-sidebar/side-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BoVtXSGO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"button\",false],[23,\"class\",\"btn btn-link text-gray pull-right hidden-md hidden-lg\",null],[3,0,0,[27,[26,3,\"ModifierHead\"],[]],[[27,[24,0],[]],[27,[26,2,\"Expression\"],[]]],null],[10],[1,1,0,0,\"\\n  \"],[1,0,0,0,[31,94,7,[27,[26,0,\"CallHead\"],[]],[\"times\"],null]],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,4,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],null,[[\"class\",\"route\"],[\"h5 text-white\",\"dashboard\"]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"    \"],[1,0,0,0,[31,199,7,[27,[26,0,\"CallHead\"],[]],[\"circle-o-notch\"],[[\"class\"],[\"text-primary\"]]]],[1,1,0,0,\"\\n    \"],[9,\"span\",true],[12,\"class\",\"h4 font-w600 sidebar-mini-hide\",null],[10],[1,1,0,0,\"ne\"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[16,1,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"link-to\",\"onClose\",\"action\",\"unless\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/main-dashboard/page-sidebar/side-header/template.hbs"
    }
  });

  _exports.default = _default;
});