define("building-management-dashboard/pods/components/transaction/table-data/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.columns = [{
        title: this.intl.t('title.date')
      }, {
        title: this.intl.t('model.property', {
          count: 1
        })
      }, {
        title: this.intl.t('title.description')
      }, {
        title: this.intl.t('title.asset')
      }, {
        align: 'right',
        title: this.intl.t('title.debit')
      }, {
        align: 'right',
        title: this.intl.t('title.credit')
      }];
    }

  });

  _exports.default = _default;
});