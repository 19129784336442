define("building-management-dashboard/pods/dashboard/projects/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      limit: {
        refreshModel: true
      },
      orderBy: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      sortedBy: {
        refreshModel: true
      }
    },

    model(params) {
      if (Ember.isNone(params.orderBy)) {
        params.orderBy = 'name';
      }

      return this.store.query('project', params);
    },

    setupController(controller, projects) {
      controller.setProperties({
        projects
      });
    },

    actions: {
      refresh() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});