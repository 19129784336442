define("building-management-dashboard/pods/dashboard/my-building/bills/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    queryParams: {
      accountType: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      orderBy: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      sortedBy: {
        refreshModel: true
      }
    },

    model(params) {
      if (Ember.isNone(params.orderBy)) {
        params.orderBy = 'created_at';
        params.sortedBy = 'desc';
      }

      params.building = this.modelFor('dashboard.my-building').get('id');
      return this.store.query('bill', params);
    },

    setupController(controller, bills) {
      const building = this.modelFor('dashboard.my-building');
      controller.setProperties({
        bills,
        building
      });
    },

    titleToken: function () {
      return this.intl.t('header.bill.page');
    },
    actions: {
      refresh() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});