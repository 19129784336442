define("building-management-dashboard/router", ["exports", "building-management-dashboard/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "location", _environment.default.locationType);

      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('login');
    this.route('dashboard', function () {
      this.route('my-building', {
        path: '/:building_id'
      }, function () {
        this.route('bills', function () {
          this.route('show', {
            path: '/:bill_id'
          });
        });
        this.route('chart-of-accounts', function () {});
        this.route('contacts', function () {
          this.route('contact', {
            path: '/:contact_id'
          }, function () {
            this.route('invoices');
            this.route('transactions');
          });
        });
        this.route('documents', function () {});
        this.route('properties', function () {});
        this.route('property-groups', function () {
          this.route('show', {
            path: '/:property_group_id'
          });
        });
        this.route('suppliers', function () {});
        this.route('transactions', function () {});
      }); // admin routes

      this.route('buildings', function () {});
      this.route('logout');
      this.route('projects', function () {});
      this.route('users', function () {});
    });
  });
});