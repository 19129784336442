define("building-management-dashboard/pods/dashboard/my-building/contacts/contact/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: Ember.computed('controller.model.fullName', {
      get() {
        return {
          title: this.get('controller.model.fullName')
        };
      }

    })
  });

  _exports.default = _default;
});