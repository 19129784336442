define("building-management-dashboard/models/user", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UserModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('date'), _dec6 = (0, _model.attr)('date'), _dec7 = (0, _model.belongsTo)({
    inverse: null
  }), _dec8 = (0, _model.hasMany)(), _dec9 = Ember.computed('firstName', 'lastName'), _dec10 = Ember.computed('roles.[]'), _dec11 = Ember.computed('roles.[]'), _dec12 = Ember.computed('roles.[]'), _dec13 = Ember.computed('roles.[]'), (_class = (_temp = class UserModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "email", _descriptor, this);

      _initializerDefineProperty(this, "firstName", _descriptor2, this);

      _initializerDefineProperty(this, "lastName", _descriptor3, this);

      _initializerDefineProperty(this, "password", _descriptor4, this);

      _initializerDefineProperty(this, "createdAt", _descriptor5, this);

      _initializerDefineProperty(this, "updatedAt", _descriptor6, this);

      _initializerDefineProperty(this, "permissions", _descriptor7, this);

      _initializerDefineProperty(this, "project", _descriptor8, this);

      _initializerDefineProperty(this, "roles", _descriptor9, this);
    }

    get fullName() {
      return `${this.firstName} ${this.lastName}`;
    }

    get isAdmin() {
      return this.isSuperAdmin || this.hasRole('admin');
    }

    get isSuperAdmin() {
      return this.hasRole('super_admin');
    }

    get isOwner() {
      return this.hasRole('owner');
    }

    get isTenant() {
      return this.hasRole('tenant');
    }

    hasRole(name) {
      const roles = this.roles || [];
      return roles.findBy('name', name) ? true : false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "email", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "firstName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "lastName", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "password", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "permissions", [_model.hasMany], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "project", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "roles", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fullName", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "fullName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isAdmin", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "isAdmin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isSuperAdmin", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "isSuperAdmin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isOwner", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "isOwner"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isTenant", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "isTenant"), _class.prototype)), _class));
  _exports.default = UserModel;
});