define("building-management-dashboard/pods/components/oneui/card/header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['block-header'],
    classNameBindings: ['_headerBg'],
    _headerBg: Ember.computed('type', function () {
      return `bg-${this.get('type')}`;
    })
  });

  _exports.default = _default;
});