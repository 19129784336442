define("building-management-dashboard/pods/components/login/account/component", ["exports", "building-management-dashboard/utils/focus-error"], function (_exports, _focusError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    identification: '',
    password: '',
    errors: Ember.computed.alias('error.responseJSON.errors'),
    errorMessage: Ember.computed('error', function () {
      return this.get('error.responseJSON.status_code') !== 422 ? this.get('error.responseJSON.message') : null;
    }),
    actions: {
      async login() {
        this.setProperties({
          disabled: true,
          error: null
        });

        try {
          const {
            identification,
            password
          } = this.getProperties('identification', 'password');
          return await this.session.authenticate('authenticator:oauth2', identification, password);
        } catch (error) {
          this.setProperties({
            error,
            disabled: false
          });
        }
      },

      submit() {
        (0, _focusError.default)(this);
      }

    }
  });

  _exports.default = _default;
});