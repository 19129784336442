define("building-management-dashboard/pods/components/oneui/button/download-file/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wyai9Ek8",
    "block": "{\"symbols\":[\"@label\",\"@size\",\"@type\"],\"statements\":[[7,\"bs-button\",[],[[\"@size\",\"@type\",\"@onClick\"],[[27,[24,2],[]],[27,[24,3],[]],[31,53,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"download\"],null]]],[[\"default\"],[{\"statements\":[[7,\"fa-icon\",[],[[\"@icon\",\"@fixedWidth\"],[\"download\",true]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[5,[27,[26,1,\"BlockHead\"],[]],[[27,[24,1],[]]],null,[[\"default\"],[{\"statements\":[[9,\"span\",true],[12,\"class\",\"push-5-l\",null],[10],[1,0,0,0,[27,[24,1],[]]],[11]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/button/download-file/template.hbs"
    }
  });

  _exports.default = _default;
});