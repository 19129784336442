define("building-management-dashboard/pods/components/oneui/swal/confirm-delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vYBAaFZl",
    "block": "{\"symbols\":[\"@confirmButtonText\",\"@show\",\"@text\",\"@onDelete\"],\"statements\":[[7,\"sweet-alert\",[],[[\"@confirmButtonText\",\"@show\",\"@showCancelButton\",\"@title\",\"@text\",\"@type\",\"@onConfirm\"],[[31,33,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,1],[]],[31,56,1,[27,[26,0,\"CallHead\"],[]],[\"button.delete\"],null]],null],[27,[24,2],[]],true,[31,129,1,[27,[26,0,\"CallHead\"],[]],[\"alert.please-confirm\"],null],[27,[24,3],[]],\"question\",[31,202,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[27,[24,4],[]]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"or\",\"action\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/swal/confirm-delete/template.hbs"
    }
  });

  _exports.default = _default;
});