define("building-management-dashboard/pods/dashboard/my-building/contacts/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    actions: {
      add() {
        this.set('selected', this.store.createRecord('contact', {
          building: this.building
        }));
      },

      cancel(contact) {
        contact.rollbackAttributes();
      },

      delete(contact) {
        contact.destroyRecord();
      },

      save() {
        return this.send('refresh');
      }

    }
  });

  _exports.default = _default;
});