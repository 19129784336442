define("building-management-dashboard/pods/components/bill/card-pending-count/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "umIE01HK",
    "block": "{\"symbols\":[\"@count\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"block text-center\",null],[10],[5,[27,[26,2,\"BlockHead\"],[]],[[31,38,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,1],[]],0],null]],null,[[\"default\",\"else\"],[{\"statements\":[[9,\"div\",true],[12,\"class\",\"block-content block-content-full\",null],[10],[9,\"div\",true],[12,\"class\",\"h1 font-w700 text-success\",null],[10],[7,\"fa-icon\",[],[[\"@icon\",\"@fixedWidth\"],[\"check\",true]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[11],[11],[9,\"div\",true],[12,\"class\",\"block-content block-content-full block-content-mini bg-gray-lighter text-success font-w600\",null],[10],[1,0,0,0,[31,307,1,[27,[26,0,\"CallHead\"],[]],[\"title.paid\"],null]],[11]],\"parameters\":[]},{\"statements\":[[9,\"div\",true],[12,\"class\",\"block-content block-content-full\",null],[10],[9,\"div\",true],[12,\"class\",\"h1 font-w700 text-primary\",null],[10],[1,0,0,0,[27,[24,1],[]]],[11],[11],[9,\"div\",true],[12,\"class\",\"block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600\",null],[10],[1,0,0,0,[31,548,1,[27,[26,0,\"CallHead\"],[]],[\"title.pending\"],null]],[11]],\"parameters\":[]}]]],[11]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/bill/card-pending-count/template.hbs"
    }
  });

  _exports.default = _default;
});