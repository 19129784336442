define("building-management-dashboard/pods/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uxyDf8Ix",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"content overflow-hidden\",null],[10],[9,\"div\",true],[12,\"class\",\"row\",null],[10],[9,\"div\",true],[12,\"class\",\"col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4\",null],[10],[7,\"login/account\",[],[[],[]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[11],[11],[11]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/login/template.hbs"
    }
  });

  _exports.default = _default;
});