define("building-management-dashboard/pods/components/document/table-data/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DocumentTableDataComponent = (_dec = Ember.inject.service('building'), (_class = (_temp = class DocumentTableDataComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "buildingService", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "swalErr", _descriptor4, this);

      _initializerDefineProperty(this, "selected", _descriptor5, this);

      _defineProperty(this, "accept", "image/*,application/pdf");

      _defineProperty(this, "columns", [{
        name: 'title',
        sortable: true,
        title: this.intl.t('title.name')
      }, {
        name: 'users:owner_id|first_name',
        sortable: true,
        title: this.intl.t('title.owner')
      }, {
        title: this.intl.t('title.size')
      }, {
        align: 'right',
        name: 'updated_at',
        sortable: true,
        title: this.intl.t('title.last-modified')
      }]);
    }

    get parents() {
      const parents = [];
      let parent = this.args.parentFolder;

      while (Ember.isPresent(parent)) {
        parents.push(parent);
        parent = parent.get('parent');
      }

      return parents.reverse();
    }

    addFolder() {
      this.selected = this.store.createRecord('attachment', {
        building: this.args.building,
        parent: this.args.parentFolder
      });
    }

    cancel() {
      this.selected.rollbackAttributes();
    }

    delete(attachment) {
      this.swalErr.run(() => attachment.destroyRecord());
    }

    async upload(file) {
      const attachment = await this.swalErr.run(() => this.buildingService.addAttachment(this.args.building, file, this.args.parentFolder), () => file.queue.set('files', []));

      if (Ember.isPresent(this.args.onSaved)) {
        this.args.onSaved(attachment);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "buildingService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "swalErr", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selected", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addFolder", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "addFolder"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "upload", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "upload"), _class.prototype)), _class));
  _exports.default = DocumentTableDataComponent;
});