define("building-management-dashboard/pods/components/oneui/card/data-table/row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    actions: {
      getValue(column) {
        const {
          key,
          name
        } = column;

        if (typeof key === 'function') {
          return key(this.item);
        }

        return this.item.get(key || name);
      }

    }
  });

  _exports.default = _default;
});