define("building-management-dashboard/services/swal-err", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    swal: Ember.inject.service(),

    async run(tryCallable, elseCallable) {
      try {
        return await tryCallable();
      } catch (err) {
        await this.swal.open({
          text: err.message || err.body?.message,
          title: 'Oops...',
          type: 'error'
        });

        if (Ember.isPresent(elseCallable)) {
          elseCallable();
        }

        throw err;
      }
    }

  });

  _exports.default = _default;
});