define("building-management-dashboard/pods/dashboard/my-building/property-groups/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      limit: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },
    intl: Ember.inject.service(),
    breadCrumb: Ember.computed(function () {
      return {
        title: this.intl.t('model.property-group', {
          count: 0
        })
      };
    }),

    model(params) {
      return this.store.query('property-group', Ember.assign(params, {
        building: this.modelFor('dashboard.my-building').get('id'),
        orderBy: 'name'
      }));
    },

    setupController(controller, propertyGroups) {
      const building = this.modelFor('dashboard.my-building');
      controller.setProperties({
        building,
        propertyGroups
      });
    },

    titleToken() {
      return this.intl.t('header.property-group.page');
    },

    actions: {
      refresh() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});