define("building-management-dashboard/pods/components/bill/table-property-bill/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    openBills: Ember.computed.filterBy('rows', 'status', 'open'),
    paidBills: Ember.computed.filterBy('rows', 'status', 'paid'),
    amounts: Ember.computed.mapBy('rows', 'amount'),
    paidAmounts: Ember.computed.mapBy('paidBills', 'amount'),
    totalAmount: Ember.computed.sum('amounts'),
    totalPaid: Ember.computed.sum('paidAmounts'),

    init() {
      this._super(...arguments);

      this.columns = [{
        title: this.intl.t('model.property', {
          count: 1
        })
      }, {
        title: this.intl.t('title.shares')
      }, {
        title: this.intl.t('model.account', {
          count: 1
        })
      }, {
        align: 'right',
        title: this.intl.t('title.amount')
      }];
    }

  });

  _exports.default = _default;
});