define("building-management-dashboard/pods/components/oneui/form/model/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0rU59tKM",
    "block": "{\"symbols\":[\"changeset\",\"form\",\"&default\",\"@validation\",\"@model\"],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[31,9,9,[27,[26,2,\"CallHead\"],[]],[[27,[24,5],[]],[27,[24,4],[]]],null]],null,[[\"default\"],[{\"statements\":[[7,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[27,[24,1],[]],[31,96,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"save\"],null]]],[[\"default\"],[{\"statements\":[[9,\"fieldset\",true],[13,\"disabled\",[27,[24,1],[\"isSaving\"]],null],[10],[9,\"button\",true],[12,\"class\",\"hidden\",null],[12,\"type\",\"submit\",null],[10],[1,1,0,0,\"Submit\"],[11],[16,3,[[27,[24,2],[]],[27,[24,1],[]]]],[5,[27,[26,1,\"BlockHead\"],[]],[[27,[24,0],[\"errorMessage\"]]],null,[[\"default\"],[{\"statements\":[[7,\"bs-alert\",[],[[\"@type\",\"@dismissible\"],[\"danger\",false]],[[\"default\"],[{\"statements\":[[7,\"fa-icon\",[[23,\"class\",\"push-5-r\",null]],[[\"@icon\",\"@fixedWidth\"],[\"exclamation-triangle\",true]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,0,0,0,[27,[24,0],[\"errorMessage\"]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[11]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"changeset\",\"with\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/form/model/template.hbs"
    }
  });

  _exports.default = _default;
});