define("building-management-dashboard/pods/dashboard/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: null,

    beforeModel() {
      const buildings = this.modelFor('dashboard');
      this.transitionTo('dashboard.my-building', buildings.get('firstObject'));
    }

  });

  _exports.default = _default;
});