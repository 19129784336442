define("building-management-dashboard/pods/components/transaction/table-data/row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    asset: Ember.computed('transaction.{creditAccount,debitAccount}', function () {
      const type = this.get('transaction.creditAccount.type');

      if (type === 'asset') {
        return this.get('transaction.creditAccount');
      }

      return this.get('transaction.debitAccount');
    }),
    contraAsset: Ember.computed('transaction.{creditAccount,debitAccount}', function () {
      const type = this.get('transaction.creditAccount.type');

      if (type === 'asset') {
        return this.get('transaction.debitAccount');
      }

      return this.get('transaction.creditAccount');
    }),
    description: Ember.computed('contraAsset.name', 'transaction.description', function () {
      return `${this.get('contraAsset.name')}: ${this.get('transaction.description')}`;
    })
  });

  _exports.default = _default;
});