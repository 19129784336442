define("building-management-dashboard/pods/components/project/modal-entry/validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    address: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    contactNumber: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 20
    })],
    currency: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 5
    })],
    country: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
      type: 'email'
    }), (0, _validators.validateLength)({
      max: 255
    })],
    name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    postalCode: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 20
    })],
    state: (0, _validators.validateLength)({
      max: 255
    }),
    website: [(0, _validators.validateFormat)({
      allowBlank: true,
      type: 'url'
    }), (0, _validators.validateLength)({
      max: 255
    })]
  };
  _exports.default = _default;
});