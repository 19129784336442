define("building-management-dashboard/pods/components/oneui/table/data-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['block', 'block-themed'],
    type: 'primary',
    canAdd: true
  });

  _exports.default = _default;
});