define("building-management-dashboard/pods/dashboard/my-building/properties/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "59VUH/2B",
    "block": "{\"symbols\":[],\"statements\":[[7,\"property/table-data\",[],[[\"@building\",\"@properties\",\"@orderBy\",\"@sortedBy\",\"@onCreated\"],[[27,[24,0],[\"building\"]],[27,[26,0,\"AppendSingleId\"],[]],[27,[26,1,\"AppendSingleId\"],[]],[27,[26,2,\"AppendSingleId\"],[]],[31,133,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],\"saved\"],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[7,\"oneui/pagination\",[],[[\"@label\",\"@model\",\"@onLimitChange\",\"@onPageChange\"],[[31,200,1,[27,[26,4,\"CallHead\"],[]],[\"model.property\"],[[\"count\"],[[27,[26,0,\"Expression\"],[\"meta\",\"pagination\",\"total\"]]]]],[27,[26,0,\"AppendSingleId\"],[\"meta\",\"pagination\"]],[31,315,11,[27,[26,8,\"CallHead\"],[]],[[31,328,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[31,336,3,[27,[26,6,\"CallHead\"],[]],[[27,[26,7,\"Expression\"],[]]],null]],null],[31,349,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[31,357,3,[27,[26,6,\"CallHead\"],[]],[[27,[26,5,\"Expression\"],[]]],null],1],null]],null],[31,388,6,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[]],[31,396,3,[27,[26,6,\"CallHead\"],[]],[[27,[26,5,\"Expression\"],[]]],null]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"properties\",\"orderBy\",\"sortedBy\",\"action\",\"t\",\"page\",\"mut\",\"limit\",\"pipe-action\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/dashboard/my-building/properties/index/template.hbs"
    }
  });

  _exports.default = _default;
});