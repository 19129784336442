define("building-management-dashboard/pods/components/dialogs/confirm-dialog/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    yesCaption: 'Yes',
    noCaption: 'No'
  });

  _exports.default = _default;
});