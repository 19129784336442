define("building-management-dashboard/pods/components/dialogs/alert-dialog/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    caption: 'OK'
  });

  _exports.default = _default;
});