define("building-management-dashboard/pods/components/oneui/file/dropzone/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    click() {
      const fileInput = this.element.querySelector('.file-upload input[type="file"]');

      if (Ember.isPresent(fileInput)) {
        fileInput.click();
      }
    }

  });

  _exports.default = _default;
});