define("building-management-dashboard/pods/components/oneui/oneui-form/bs-textarea/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "omKIvAvL",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,9,\"BlockHead\"],[]],null,[[\"errors\"],[[27,[26,8,\"Expression\"],[]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"label\",true],[13,\"for\",[32,[[31,65,6,[27,[26,1,\"CallHead\"],[]],[\"input-\",[27,[26,0,\"Expression\"],[]]],null]]],null],[10],[1,1,0,0,\"\\n    \"],[1,0,0,0,[27,[26,2,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\\n  \"],[1,0,0,0,[31,125,8,[27,[26,7,\"CallHead\"],[]],null,[[\"autofocus\",\"class\",\"id\",\"placeholder\",\"rows\",\"value\"],[[27,[26,6,\"Expression\"],[]],\"form-control\",[31,191,6,[27,[26,1,\"CallHead\"],[]],[\"input-\",[27,[26,0,\"Expression\"],[]]],null],[27,[26,5,\"Expression\"],[]],[27,[26,4,\"Expression\"],[]],[27,[26,3,\"Expression\"],[]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"elementId\",\"concat\",\"label\",\"value\",\"rows\",\"placeholder\",\"autofocus\",\"textarea\",\"errors\",\"oneui/oneui-form/input-holder\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/oneui-form/bs-textarea/template.hbs"
    }
  });

  _exports.default = _default;
});