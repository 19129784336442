define("building-management-dashboard/pods/dashboard/my-building/suppliers/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      limit: {
        refreshModel: true
      },
      orderBy: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      sortedBy: {
        refreshModel: true
      }
    },

    model(params) {
      params.building = this.modelFor('dashboard.my-building').get('id');

      if (Ember.isNone(params.orderBy)) {
        params.orderBy = 'name';
      }

      return Ember.get(this, 'store').query('supplier', params);
    },

    setupController(controller, suppliers) {
      controller.setProperties({
        suppliers,
        building: this.modelFor('dashboard.my-building')
      });
    },

    actions: {
      refresh() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});