define("building-management-dashboard/pods/components/oneui/select/property-group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    async init() {
      this._super(...arguments);

      const propertyGroups = await this.store.query('property-group', {
        building: this.building.id,
        limit: false,
        orderBy: 'name'
      });
      this.set('propertyGroups', propertyGroups);
    },

    value: Ember.computed('selected', {
      async get() {
        const propertyGroups = await this.propertyGroups;

        if (Ember.isPresent(propertyGroups)) {
          return propertyGroups.findBy('id', this.get('selected.id'));
        }

        return null;
      },

      set(key, value) {
        this.set('selected', value);
        return value;
      }

    })
  });

  _exports.default = _default;
});