define("building-management-dashboard/pods/components/coa/table-data/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.columns = [{}, {
        name: 'code',
        sortable: true,
        title: this.intl.t('title.code')
      }, {
        name: 'name',
        sortable: true,
        title: this.intl.t('title.name')
      }, {
        align: 'center',
        name: 'type',
        sortable: true,
        title: this.intl.t('title.type')
      }, {
        align: 'right',
        name: 'updated_at',
        sortable: true,
        title: this.intl.t('title.last-modified')
      }];
    }

  });

  _exports.default = _default;
});