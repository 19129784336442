define("building-management-dashboard/pods/components/transaction/io-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5YXia5qJ",
    "block": "{\"symbols\":[],\"statements\":[[9,\"tbody\",true],[10],[9,\"tr\",true],[10],[9,\"td\",true],[12,\"class\",\"h4 text-right\",null],[10],[1,0,0,0,[31,39,1,[27,[26,0,\"CallHead\"],[]],[\"title.balance\"],null]],[1,1,0,0,\":\"],[11],[9,\"td\",true],[12,\"class\",\"h3 text-right\",null],[10],[1,0,0,0,[31,92,13,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[\"total\"]]],[[\"format\"],[\"EUR\"]]]],[11],[11],[11]],\"hasEval\":false,\"upvars\":[\"t\",\"format-number\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/transaction/io-summary/template.hbs"
    }
  });

  _exports.default = _default;
});