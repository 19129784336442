define("building-management-dashboard/pods/dashboard/my-building/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "35EqyefW",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"content\",null],[10],[1,0,0,0,[31,23,17,[27,[26,1,\"CallHead\"],[]],null,[[\"error\"],[[27,[26,0,\"Expression\"],[]]]]]],[11]],\"hasEval\":false,\"upvars\":[\"model\",\"oneui/oneui-error\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/dashboard/my-building/error/template.hbs"
    }
  });

  _exports.default = _default;
});