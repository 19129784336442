define("building-management-dashboard/pods/dashboard/my-building/property-groups/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      add() {
        this.set('selected', this.store.createRecord('property-group', {
          building: this.building
        }));
      },

      cancel(propertyGroup) {
        propertyGroup.rollbackAttributes();
      },

      saved() {
        this.send('refresh');
      }

    }
  });

  _exports.default = _default;
});