define("building-management-dashboard/pods/components/bill/summary/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    propertyShares: Ember.computed.mapBy('properties', 'shares'),
    totalShares: Ember.computed.sum('propertyShares')
  });

  _exports.default = _default;
});