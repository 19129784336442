define("building-management-dashboard/pods/components/contacts/collapsible-property-contract/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OJ06cy74",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"a\",false],[14,\"class\",[32,[\"list-group-item \",[31,69,2,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]],\"active\"],null]]],null],[3,0,0,[27,[26,3,\"ModifierHead\"],[]],[[27,[24,0],[]],[31,13,13,[27,[26,2,\"CallHead\"],[]],[\"active\",[27,[24,0],[]]],null]],null],[10],[9,\"span\",true],[12,\"class\",\"pull-right\",null],[10],[1,0,0,0,[31,118,1,[27,[26,4,\"CallHead\"],[]],[\"title.balance\"],null]],[1,1,0,0,\":\"],[9,\"strong\",true],[12,\"class\",\"push-5-l\",null],[10],[1,0,0,0,[31,165,25,[27,[26,6,\"CallHead\"],[]],null,[[\"amount\"],[[27,[26,5,\"Expression\"],[]]]]]],[11],[11],[1,0,0,0,[27,[26,7,\"Expression\"],[\"name\"]]],[9,\"small\",true],[12,\"class\",\"push-5-l\",null],[10],[1,0,0,0,[27,[26,8,\"Expression\"],[\"name\"]]],[1,1,0,0,\" (\"],[1,0,0,0,[27,[26,7,\"Expression\"],[\"shares\"]]],[1,1,0,0,\"/\"],[1,0,0,0,[27,[26,8,\"Expression\"],[\"shares\"]]],[1,1,0,0,\")\"],[11],[11],[5,[27,[26,10,\"BlockHead\"],[]],null,[[\"collapsed\"],[[31,366,3,[27,[26,9,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null]]],[[\"default\"],[{\"statements\":[[16,1,null]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"active\",\"if\",\"toggle-action\",\"action\",\"t\",\"_balance\",\"transactions/label-amount\",\"_property\",\"_building\",\"not\",\"bs-collapse\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/contacts/collapsible-property-contract/template.hbs"
    }
  });

  _exports.default = _default;
});