define("building-management-dashboard/pods/components/bill/alert-delete/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J2tw/bWr",
    "block": "{\"symbols\":[\"@show\",\"@bill\"],\"statements\":[[7,\"sweet-alert\",[],[[\"@allowOutsideClick\",\"@cancelButtonText\",\"@confirmButtonText\",\"@input\",\"@inputPlaceholder\",\"@show\",\"@showCancelButton\",\"@showLoaderOnConfirm\",\"@text\",\"@title\",\"@type\",\"@preConfirm\"],[false,[31,61,1,[27,[26,0,\"CallHead\"],[]],[\"button.cancel\"],null],[31,102,1,[27,[26,0,\"CallHead\"],[]],[\"button.delete\"],null],\"checkbox\",[31,160,1,[27,[26,0,\"CallHead\"],[]],[\"placeholder.force-delete\"],null],[27,[24,1],[]],true,true,[31,272,1,[27,[26,0,\"CallHead\"],[]],[\"alert.bill.force-delete\"],null],[31,311,1,[27,[26,0,\"CallHead\"],[]],[\"alert.bill.delete\"],[[\"name\"],[[27,[24,2],[\"chartOfAccount\",\"name\"]]]]],\"question\",[31,397,5,[27,[26,3,\"CallHead\"],[]],[[31,404,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],\"preConfirm\"],null],[31,426,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[31,434,3,[27,[26,1,\"CallHead\"],[]],[[27,[24,1],[]]],null],false],null]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"mut\",\"action\",\"queue\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/bill/alert-delete/template.hbs"
    }
  });

  _exports.default = _default;
});