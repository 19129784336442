define("building-management-dashboard/services/bill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),

    async addAttachment(bill, file) {
      try {
        const response = await file.upload(`/api/bills/${bill.get('id')}/attachments`, {
          headers: {
            Authorization: `Bearer ${this.session.data.authenticated.access_token}`
          }
        });
        return response.body;
      } catch (err) {
        if (err.status === 422) {
          const message = Ember.get(err, 'body.errors.file.firstObject');
          throw new Error(message);
        }

        throw err;
      }
    }

  });

  _exports.default = _default;
});