define("building-management-dashboard/pods/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7xnZZkBy",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"content\",null],[10],[7,\"oneui/oneui-error\",[],[[\"@error\"],[[27,[24,1],[]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[11]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/error/template.hbs"
    }
  });

  _exports.default = _default;
});