define("building-management-dashboard/pods/components/oneui/modal/entry/component", ["exports", "building-management-dashboard/utils/focus-error"], function (_exports, _focusError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    backdropClose: false,
    size: 'md',
    errorMessage: Ember.computed.alias('error.message'),
    hasError: Ember.computed.bool('error.status_code'),
    actions: {
      submit() {
        (0, _focusError.default)(this);
      }

    }
  });

  _exports.default = _default;
});