define("building-management-dashboard/pods/dashboard/my-building/bills/index/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      add() {
        this.set('selected', this.store.createRecord('bill', {
          billedAt: (0, _moment.default)().format('YYYY-MM-DD'),
          building: this.building
        }));
      },

      cancel(bill) {
        bill.rollbackAttributes();
      },

      deleted() {
        this.send('refresh');
      },

      async saved(paymentAccount, bill) {
        if (Ember.isPresent(paymentAccount)) {
          const propertyBills = await bill.get('propertyBills');
          propertyBills.forEach(async propertyBill => {
            propertyBill.paymentAccount = paymentAccount;
            await propertyBill.save({
              adapterOptions: {
                pay: true
              }
            });
          });
        }

        this.transitionToRoute('dashboard.my-building.bills.show', bill.get('id'));
      }

    }
  });

  _exports.default = _default;
});