define("building-management-dashboard/pods/components/transaction/io-summary/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'table',
    inflow: Ember.computed.alias('totalCashFlow.inflow'),
    outflow: Ember.computed.alias('totalCashFlow.outflow'),
    total: Ember.computed.alias('totalCashFlow.total')
  });

  _exports.default = _default;
});