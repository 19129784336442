define("building-management-dashboard/utils/focus-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = focusError;

  function focusError(component) {
    component.element.querySelector('button[type="submit"]').click();
    Ember.run.next(this, function () {
      const invalidInput = document.querySelector('.has-error input, .has-error textarea, .has-error .ember-basic-dropdown-trigger');
      focus(invalidInput);
    });

    function focus(input) {
      if (Ember.isPresent(input)) {
        input.focus();
      }
    }
  }
});