define("building-management-dashboard/pods/components/oneui/oneui-form/bs-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bwAlwZjx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,0,0,0,[31,2,5,[27,[26,5,\"CallHead\"],[]],null,[[\"autofocus\",\"checked\",\"disabled\",\"name\",\"type\"],[[27,[26,4,\"Expression\"],[]],[27,[26,3,\"Expression\"],[]],[27,[26,2,\"Expression\"],[]],[27,[26,1,\"Expression\"],[]],\"checkbox\"]]]],[1,1,0,0,\"\\n\\n\"],[9,\"span\",true],[10],[11],[1,1,0,0,\"\\n\\n\"],[5,[27,[26,6,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,1,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"label\",\"name\",\"disabled\",\"checked\",\"autofocus\",\"input\",\"if\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/oneui-form/bs-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});