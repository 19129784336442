define("building-management-dashboard/pods/components/oneui/oneui-layout/sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p8rYoKaA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[31,9,4,[27,[26,1,\"CallHead\"],[]],null,[[\"body\",\"header\"],[[31,20,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/oneui-layout/sidebar/body\"],null],[31,73,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/oneui-layout/sidebar/header\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/oneui-layout/sidebar/template.hbs"
    }
  });

  _exports.default = _default;
});