define("building-management-dashboard/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "alert": {
      "attachment": {
        "summary": "{count, plural,\n  =0 {attachments}\n  other {({count}) attachments}\n}\n"
      },
      "bill": {
        "delete": "Delete this bill, {name}?",
        "force-delete": "Force delete skips the transaction constraints.",
        "pay": "Pay {amount, number, EUR}"
      },
      "building": {
        "delete": "Delete this building, {name}?"
      },
      "coa": {
        "delete": "Delete this account, {name}?"
      },
      "contact": {
        "delete": "Delete this contact, {name}?"
      },
      "document": {
        "delete": "Delete this document, {name}?"
      },
      "drop-upload": "Drop to upload",
      "invalid": "Invalid",
      "loading": "Please wait...",
      "login": {
        "welcome": "Welcome, please login."
      },
      "n-items": "{count} items",
      "no-matches": "No results found.",
      "no-records": "No records returned.",
      "please-confirm": "Please confirm",
      "project": {
        "delete": "Delete this project, {name}?"
      },
      "property": {
        "delete": "Delete this property, {name}?"
      },
      "property-group": {
        "delete": "Delete this property group, {name}?"
      },
      "shares": "Shares: {shares}",
      "showing-records": "Showing {start}-{end} of {total} {resource}",
      "supplier": {
        "delete": "Delete this supplier, {name}?"
      },
      "total-shares": "Total Shares: {shares}",
      "upload-files": "Uploading {count} files. ({progress}%)",
      "user": {
        "delete": "Delete this user, {name}?"
      }
    },
    "button": {
      "add": "Add",
      "add-attachment": "Add attachment",
      "cancel": "Cancel",
      "close": "Close",
      "delete": "Delete",
      "edit": "Edit",
      "export-pdf": "Export PDF",
      "filter": "Filter",
      "force-delete": "Force delete",
      "mark-paid": "Mark paid",
      "ok": "OK",
      "save": "Save",
      "upload": "Upload",
      "view": "View"
    },
    "header": {
      "bill": {
        "create": "Add new bill",
        "page": "Bills Management",
        "pay": "Bill payment"
      },
      "building": {
        "create": "Add new building",
        "edit": "Edit building {name}",
        "page": "Buildings Management"
      },
      "coa": {
        "create": "Add new account",
        "edit": "Edit account {name}",
        "page": "Accounts Management"
      },
      "contact": {
        "create": "Add new contact",
        "edit": "Edit contact {name}",
        "page": "Contacts Management"
      },
      "document": {
        "create": "Add new folder",
        "page": "Documents Management"
      },
      "invoice": {
        "page": "Invoices Management"
      },
      "login": {
        "page": "Login"
      },
      "project": {
        "create": "Add new project",
        "edit": "Edit project {name}",
        "page": "Projects Management"
      },
      "property": {
        "create": "Add new property",
        "edit": "Edit property {name}",
        "page": "Properties Management"
      },
      "property-group": {
        "create": "Add new property",
        "page": "Property Groups Management"
      },
      "supplier": {
        "create": "Add new supplier",
        "edit": "Edit supplier {name}",
        "page": "Suppliers Management"
      },
      "transaction": {
        "page": "Transactions Management"
      },
      "user": {
        "create": "Add new user",
        "edit": "Edit user {name}",
        "page": "Users Management"
      }
    },
    "model": {
      "account": "{count, plural, one {account} other {accounts}}\n",
      "administrator": "{count, plural, one {administrator} other {administrators}}\n",
      "attachment": "{count, plural, one {attachment} other {attachments}}\n",
      "bill": "{count, plural, one {bill} other {bills}}\n",
      "building": "{count, plural, one {building} other {buildings}}\n",
      "contact": "{count, plural, one {contact} other {contacts}}\n",
      "document": "{count, plural, =1 {document} other {documents}}\n",
      "invoice": "{count, plural, one {invoice} other {invoices}}\n",
      "project": "{count, plural, one {project} other {projects}}\n",
      "property": "{count, plural, one {property} other {properties}}\n",
      "property-bill": "{count, plural, one {property bill} other {property bills}}\n",
      "property-group": "{count, plural, one {property group} other {property groups}}\n",
      "role": "{count, plural, one {role} other {roles}}\n",
      "supplier": "{count, plural, one {supplier} other {suppliers}}\n",
      "transaction": "{count, plural, one {transaction} other {transactions}}\n",
      "user": "{count, plural, one {user} other {users}}\n"
    },
    "placeholder": {
      "dropzone": "Drag and drop files to upload",
      "force-delete": "Force delete",
      "select-account": "Select an account",
      "select-bill-type": "Select bill type",
      "select-contact": "Select a contact",
      "select-country": "Select a country",
      "select-project": "Select a project",
      "select-properties": "Select properties",
      "select-property": "Select a property",
      "select-roles": "Select roles",
      "select-supplier": "Select a supplier",
      "select-type": "Select a type"
    },
    "title": {
      "actions": "Actions",
      "address": "Address",
      "admin": "Admin",
      "amount": "Amount",
      "app": "BM Dashboard",
      "asset": "Asset",
      "balance": "Balance",
      "building": "Building",
      "capital": "Capital",
      "code": "Code",
      "contact-number": "Contact number",
      "contact-person": "Contact person",
      "country": "Country",
      "credit": "Credit",
      "currency": "Currency",
      "date": "Date",
      "debit": "Debit",
      "description": "Description",
      "download": "Download",
      "email": "E-mail",
      "expense": "Expense",
      "first-name": "First name",
      "forgot-password": "Forgot password?",
      "from": "From",
      "income": "Income",
      "languages": "Languages",
      "last-modified": "Last modified",
      "last-name": "Last name",
      "limit": "Limit",
      "login": "Log in",
      "logout": "Logout",
      "name": "Name",
      "owner": "Owner",
      "paid": "Paid",
      "parent": "Parent",
      "password": "Password",
      "payment": "Payment",
      "pending": "Pending",
      "postal-code": "Postal Code",
      "project": "Project",
      "property-group": "Property group",
      "reference": "Reference number",
      "root": "Root",
      "shares": "Shares",
      "signup": "Sign up",
      "size": "Size",
      "state": "State",
      "supplier": "Supplier",
      "tax-number": "Tax number",
      "title": "Title",
      "to": "To",
      "total-amount": "Total amount",
      "total-due": "Total due",
      "total-paid": "Total paid",
      "total-shares": "Total shares",
      "type": "Type",
      "username": "Username",
      "website": "Website"
    },
    "validations": {
      "email": "{description} must be a valid email address",
      "greaterThan": "{description} must be greater than {gt}",
      "phone": "{description} must be a valid phone number",
      "present": "{description} can't be blank",
      "tooLong": "{description} is too long (maximum is {max} characters)",
      "url": "{description} must be a valid url"
    }
  };
  _exports.default = _default;
});