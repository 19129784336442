define("building-management-dashboard/pods/components/oneui/card/content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['block-content'],
    classNameBindings: ['full:block-content-full', 'noPadding:remove-padding'],
    noPadding: false
  });

  _exports.default = _default;
});