define("building-management-dashboard/pods/dashboard/my-building/documents/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let DashboardMyBuildingDocumentsIndexRoute = (_class = (_temp = class DashboardMyBuildingDocumentsIndexRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        limit: {
          refreshModel: true
        },
        orderBy: {
          refreshModel: true
        },
        page: {
          refreshModel: true
        },
        parent: {
          refreshModel: true
        },
        sortedBy: {
          refreshModel: true
        }
      });
    }

    model(params) {
      params.building = this.modelFor('dashboard.my-building').id;
      return Ember.RSVP.hash({
        attachments: this.store.query('attachment', params),
        parentFolder: Ember.isPresent(params.parent) ? this.store.findRecord('attachment', params.parent) : null
      });
    }

    setupController(controller, {
      attachments,
      parentFolder
    }) {
      controller.setProperties({
        attachments,
        parentFolder,
        building: this.modelFor('dashboard.my-building')
      });
    }

    refreshModel() {
      this.refresh();
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "refreshModel", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshModel"), _class.prototype)), _class);
  _exports.default = DashboardMyBuildingDocumentsIndexRoute;
});