define("building-management-dashboard/pods/components/oneui/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bno/8wM2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"bs-alert\",[[23,\"class\",\"h3\",null]],[[\"@dismissible\",\"@type\"],[false,\"warning\"]],[[\"default\"],[{\"statements\":[[9,\"p\",true],[10],[7,\"fa-icon\",[[23,\"class\",\"push-5-r\",null]],[[\"@icon\",\"@fixedWidth\",\"@spin\"],[\"circle-notch\",true,true]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[9,\"span\",true],[10],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[11],[11]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"title\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/loading/template.hbs"
    }
  });

  _exports.default = _default;
});