define("building-management-dashboard/pods/components/project/modal-entry/component", ["exports", "@glimmer/component", "building-management-dashboard/pods/components/project/modal-entry/validation"], function (_exports, _component, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ProjectModalEntryComponent extends _component.default {
    constructor() {
      super(...arguments);
      this.validation = _validation.default;
    }

  }

  _exports.default = ProjectModalEntryComponent;
});