define("building-management-dashboard/pods/components/oneui/main-dashboard/page-sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JOLaEzWR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"div\",true],[12,\"id\",\"sidebar-scroll\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[12,\"class\",\"sidebar-content\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,5,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"      \"],[1,0,0,0,[31,91,45,[27,[26,3,\"CallHead\"],[]],null,[[\"onClose\"],[[31,146,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"close\"],null]]]]],[1,1,0,0,\"\\n      \"],[1,0,0,0,[27,[26,4,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"      \"],[16,1,[[31,257,4,[27,[26,2,\"CallHead\"],[]],null,[[\"header\",\"content\"],[[31,280,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/main-dashboard/page-sidebar/side-header\"],[[\"onClose\"],[[31,347,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"close\"],null]]]],[31,383,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/main-dashboard/page-sidebar/side-content\"],null]]]]]],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"  \"],[11],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"component\",\"action\",\"hash\",\"oneui/main-dashboard/page-sidebar/side-header\",\"oneui/main-dashboard/page-sidebar/side-content\",\"unless\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/main-dashboard/page-sidebar/template.hbs"
    }
  });

  _exports.default = _default;
});