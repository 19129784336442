define("building-management-dashboard/pods/components/oneui/card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UYWYSiZ6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[31,9,4,[27,[26,3,\"CallHead\"],[]],null,[[\"content\",\"dataTable\",\"header\",\"table\"],[[31,23,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/card/content\"],null],[31,66,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/card/data-table\"],null],[31,109,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/card/header\"],[[\"title\",\"type\"],[[27,[26,2,\"Expression\"],[]],[27,[26,1,\"Expression\"],[]]]]],[31,169,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/card/table\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"type\",\"title\",\"hash\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/card/template.hbs"
    }
  });

  _exports.default = _default;
});