define("building-management-dashboard/pods/components/layouts/page-default/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NvVtZHoh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"div\",true],[12,\"class\",\"content bg-gray-lighter\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[12,\"class\",\"row items-push\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-7\",null],[10],[1,1,0,0,\"\\n      \"],[9,\"h1\",true],[12,\"class\",\"page-heading\",null],[10],[1,1,0,0,\"\\n        \"],[1,0,0,0,[27,[26,1,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n\"],[5,[27,[26,2,\"BlockHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null,[[\"default\"],[{\"statements\":[[1,1,0,0,\"          \"],[9,\"small\",true],[10],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[1,1,0,0,\"      \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\\n    \"],[9,\"div\",true],[12,\"class\",\"col-sm-5 text-right hidden-xs\",null],[10],[1,1,0,0,\"\\n      \"],[1,0,0,0,[27,[26,3,\"AppendSingleId\"],[]]],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"content\",null],[10],[1,1,0,0,\"\\n  \"],[16,1,null],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"subheader\",\"header\",\"if\",\"bread-crumbs\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/layouts/page-default/template.hbs"
    }
  });

  _exports.default = _default;
});