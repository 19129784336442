define("building-management-dashboard/pods/components/oneui/select/property-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "McLdLPGW",
    "block": "{\"symbols\":[\"group\",\"@onChange\",\"@disabled\"],\"statements\":[[5,[27,[26,5,\"BlockHead\"],[]],null,[[\"allowClear\",\"disabled\",\"noMatchesMessage\",\"options\",\"placeholder\",\"renderInPlace\",\"searchEnabled\",\"selected\",\"searchField\",\"onChange\"],[true,[27,[24,3],[]],[31,69,1,[27,[26,4,\"CallHead\"],[]],[\"alert.no-matches\"],null],[27,[24,0],[\"propertyGroups\"]],[31,132,1,[27,[26,4,\"CallHead\"],[]],[\"placeholder.select-account\"],null],true,true,[27,[24,0],[\"value\"]],\"name\",[31,251,11,[27,[26,3,\"CallHead\"],[]],[[31,264,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],[31,272,3,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[\"value\"]]],null]],null],[31,290,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],[31,298,8,[27,[26,0,\"CallHead\"],[]],[[27,[24,2],[]]],null],[27,[24,0],[\"value\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[1,0,0,0,[27,[24,1],[\"name\"]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"optional\",\"action\",\"mut\",\"pipe-action\",\"t\",\"power-select\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/select/property-group/template.hbs"
    }
  });

  _exports.default = _default;
});