define("building-management-dashboard/pods/components/oneui/oneui-panel/panel-table/header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['_align', '_sorting'],
    tagName: 'th',
    align: 'left',
    name: null,
    orderBy: null,
    sortable: false,
    sortedBy: null,
    _align: Ember.computed('align', function () {
      return `text-${this.get('align')}`;
    }),
    _sorting: Ember.computed('name', 'orderBy', 'sortable', 'sortedBy', function () {
      if (this.get('sortable') === false) {
        return null;
      }

      const name = this.get('name');
      const orderBy = this.get('orderBy');
      const sortedBy = this.get('sortedBy');

      if (name === orderBy) {
        if (sortedBy === 'asc') {
          return 'sorting_asc';
        }

        if (sortedBy === 'desc') {
          return 'sorting_desc';
        }
      }

      return 'sorting';
    }),

    click() {
      if (this.get('sortable')) {
        const sortedBy = this.get('sortedBy') === 'asc' ? 'desc' : 'asc';
        this.setProperties({
          sortedBy,
          orderBy: this.get('name')
        });
      }
    }

  });

  _exports.default = _default;
});