define("building-management-dashboard/pods/components/oneui/dropdown/building/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    routeName: Ember.computed('router.currentRouteName', function () {
      const name = this.router.get('currentRouteName');

      if (name.indexOf('my-building') !== -1) {
        return name;
      }

      return 'dashboard.my-building';
    })
  });

  _exports.default = _default;
});