define("building-management-dashboard/pods/components/oneui/select/chart-of-account/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    accounts: Ember.computed('building', 'contraType', 'except', 'noParent', 'types', function () {
      const {
        building,
        contraType,
        except,
        noParent,
        types
      } = this.getProperties('building', 'contraType', 'except', 'noParent', 'types');

      if (Ember.isNone(building)) {
        return [];
      }

      return this.store.query('chart-of-account', {
        contraType,
        except,
        noParent,
        types,
        building: building.get('id'),
        limit: false,
        orderBy: 'name'
      });
    }),
    value: Ember.computed('selected.id', 'accounts.[]', {
      async get() {
        const id = this.get('selected.id');
        return (await this.get('accounts')).findBy('id', id);
      },

      set(key, account) {
        this.set('selected', account);
        return account;
      }

    }),

    matcher({
      code,
      name
    }, term) {
      return `${name} ${code}`.toLowerCase().indexOf(term);
    }

  });

  _exports.default = _default;
});