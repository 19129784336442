define("building-management-dashboard/pods/components/oneui/select/project/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yB2zDA9n",
    "block": "{\"symbols\":[\"project\"],\"statements\":[[5,[27,[26,9,\"BlockHead\"],[]],null,[[\"allowClear\",\"disabled\",\"noMatchesMessage\",\"options\",\"placeholder\",\"renderInPlace\",\"searchEnabled\",\"searchField\",\"selected\",\"onChange\"],[true,[27,[26,8,\"Expression\"],[]],[31,68,1,[27,[26,6,\"CallHead\"],[]],[\"alert.no-matches\"],null],[27,[26,7,\"Expression\"],[]],[31,120,1,[27,[26,6,\"CallHead\"],[]],[\"placeholder.select-project\"],null],true,true,\"name\",[27,[26,3,\"Expression\"],[]],[31,237,5,[27,[26,5,\"CallHead\"],[]],[[31,244,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[31,252,3,[27,[26,4,\"CallHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null]],null],[31,268,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[31,276,8,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null]],null]],null]]],[[\"default\"],[{\"statements\":[[1,0,0,0,[27,[24,1],[\"name\"]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"onChange\",\"optional\",\"action\",\"selected\",\"mut\",\"queue\",\"t\",\"projects\",\"disabled\",\"power-select\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/select/project/template.hbs"
    }
  });

  _exports.default = _default;
});