define("building-management-dashboard/pods/components/oneui/card/table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xEXocDG1",
    "block": "{\"symbols\":[\"@columns\",\"@orderBy\",\"@showActionColumn\",\"@sortedBy\",\"@sortable\",\"&default\",\"@showEmptyMessage\"],\"statements\":[[9,\"table\",true],[13,\"class\",[32,[\"table dataTable \",[31,32,4,[27,[26,1,\"CallHead\"],[]],[\" \",[27,[24,0],[\"tableClassNames\"]]],null]]],null],[10],[7,\"oneui/card/table/thead\",[],[[\"@columns\",\"@orderBy\",\"@showActionColumn\",\"@sortedBy\",\"@sortable\"],[[27,[24,1],[]],[27,[24,2],[]],[27,[24,3],[]],[27,[24,4],[]],[27,[24,5],[]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[16,6,null],[5,[27,[26,2,\"BlockHead\"],[]],[[27,[24,7],[]]],null,[[\"default\"],[{\"statements\":[[9,\"tfoot\",true],[10],[9,\"tr\",true],[10],[9,\"td\",true],[13,\"colspan\",[32,[[31,312,3,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"length\"]]],null]]],null],[10],[7,\"oneui/empty-list\",[],[[],[]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[11],[11],[11]],\"parameters\":[]}]]],[11]],\"hasEval\":false,\"upvars\":[\"inc\",\"join\",\"if\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/card/table/template.hbs"
    }
  });

  _exports.default = _default;
});