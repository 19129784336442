define("building-management-dashboard/adapters/attachment", ["exports", "building-management-dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForCreateRecord(modelName, snapshot) {
      const path = this._super(...arguments);

      return path.replace('/attachments', `/buildings/${snapshot.belongsTo('building').id}/attachments`);
    }

  });

  _exports.default = _default;
});