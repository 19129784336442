define("building-management-dashboard/pods/components/transaction/table-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W/JxnEks",
    "block": "{\"symbols\":[\"panel\",\"transaction\",\"@transactions\"],\"statements\":[[7,\"oneui/card\",[],[[],[]],[[\"default\"],[{\"statements\":[[1,0,0,0,[31,0,0,[27,[26,1,\"CallHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"header\"]],\"expected `panel.header` to be a contextual component but found a string. Did you mean `(component panel.header)`? ('building-management-dashboard/pods/components/transaction/table-data/template.hbs' @ L1:C26) \"],null]],[[\"title\"],[[31,46,1,[27,[26,2,\"CallHead\"],[]],[\"model.transaction\"],[[\"count\"],[0]]]]]]],[5,[27,[26,1,\"BlockHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"content\"]],\"expected `panel.content` to be a contextual component but found a string. Did you mean `(component panel.content)`? ('building-management-dashboard/pods/components/transaction/table-data/template.hbs' @ L1:C81) \"],null]],[[\"class\"],[\"remove-padding\"]],[[\"default\"],[{\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"dataTable\"]],\"expected `panel.dataTable` to be a contextual component but found a string. Did you mean `(component panel.dataTable)`? ('building-management-dashboard/pods/components/transaction/table-data/template.hbs' @ L1:C122) \"],null]],[[\"columns\",\"rows\",\"showActionColumn\"],[[27,[24,0],[\"columns\"]],[27,[24,3],[]],false]],[[\"default\"],[{\"statements\":[[7,\"transaction/table-data/row\",[],[[\"@transaction\"],[[27,[24,2],[]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"parameters\":[2]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-assert-implicit-component-helper-argument\",\"component\",\"t\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/transaction/table-data/template.hbs"
    }
  });

  _exports.default = _default;
});