define("building-management-dashboard/pods/dashboard/my-building/transactions/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    breadCrumb: Ember.computed(function () {
      return {
        title: this.intl.t('model.transaction', {
          count: 0
        })
      };
    })
  });

  _exports.default = _default;
});