define("building-management-dashboard/pods/components/user/modal-entry/component", ["exports", "building-management-dashboard/pods/components/user/modal-entry/validation"], function (_exports, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    Validation: _validation.default,
    showPassword: true,
    actions: {
      async save(user) {
        this.set('error', null);
        const {
          onSaved
        } = this.getProperties('onSaved');

        try {
          await user.save();

          if (Ember.isPresent(onSaved)) {
            return onSaved(user);
          }
        } catch (error) {
          this.set('error', error);
        }
      }

    }
  });

  _exports.default = _default;
});