define("building-management-dashboard/services/language-cookie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const KEY = 'bm-lang';

  var _default = Ember.Service.extend({
    language: null,
    cookies: Ember.inject.service(),

    getLanguage() {
      this.set('language', this.cookies.read(KEY) || 'en-us');
      return this.get('language');
    },

    setLanguage(lang) {
      this.set('language', lang);
      this.cookies.write(KEY, lang, {
        path: '/'
      });
      window.location.reload(true);
    }

  });

  _exports.default = _default;
});