define("building-management-dashboard/models/contact", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    address: attr('string'),
    contactNumber: attr('string'),
    country: attr('string'),
    firstName: attr('string'),
    email: attr('string'),
    lastName: attr('string'),
    state: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    building: belongsTo(),
    project: belongsTo(),
    fullName: Ember.computed('firstName', 'lastName', function () {
      return `${this.firstName} ${this.lastName}`;
    })
  });

  _exports.default = _default;
});