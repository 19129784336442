define("building-management-dashboard/pods/dashboard/my-building/chart-of-accounts/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    swalErr: Ember.inject.service(),
    actions: {
      add() {
        this.set('selected', this.store.createRecord('chart-of-account', {
          building: this.get('building')
        }));
      },

      cancel(account) {
        account.rollbackAttributes();
      },

      delete(account) {
        return this.swalErr.run(() => account.destroyRecord());
      },

      saved() {
        this.send('refresh');
      }

    }
  });

  _exports.default = _default;
});