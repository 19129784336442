define("building-management-dashboard/pods/dashboard/my-building/property-groups/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      cancel(propertyGroup) {
        propertyGroup.rollbackAttributes();
      },

      delete(propertyGroup) {
        propertyGroup.destroyRecord();
        this.transitionToRoute('dashboard.my-building.property-groups');
      }

    }
  });

  _exports.default = _default;
});