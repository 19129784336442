define("building-management-dashboard/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "building-management-dashboard/config/environment"], function (_exports, _emberData, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RESTAdapter,
    errorsHashToArray
  } = _emberData.default;
  const {
    APP: {
      apiHost,
      apiNamespace
    }
  } = _environment.default;

  var _default = RESTAdapter.extend(_dataAdapterMixin.default, {
    host: apiHost,
    namespace: apiNamespace,
    coalesceFindRequests: true,
    headers: Ember.computed('session.data.authenticated.access_token', function () {
      if (!this.session.isAuthenticated) {
        return {};
      }

      return {
        Authorization: `Bearer ${this.session.data.authenticated.access_token}`
      };
    }),

    handleResponse(status, headers, payload) {
      if (this.isInvalid(...arguments)) {
        payload.errors = errorsHashToArray(payload.errors);
        return this._super(...arguments);
      }

      return payload;
    },

    urlForFindMany() {
      return `${this._super(...arguments)}?limit=false`;
    }

  });

  _exports.default = _default;
});