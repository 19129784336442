define("building-management-dashboard/pods/dashboard/buildings/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    swal: Ember.inject.service(),
    actions: {
      add() {
        this.set('selected', this.store.createRecord('building', {
          shares: 1000
        }));
      },

      cancel(building) {
        building.rollbackAttributes();
      },

      delete(building) {
        building.destroyRecord();
      },

      saved() {
        return this.send('refresh');
      },

      saveUpload(selected, {
        file,
        name
      }) {
        const data = new FormData();
        data.append('file', file);
        data.append('name', name);
        return this.get('ajax').post(`/api/buildings/${selected.id}/uploadAsset`, {
          data,
          contentType: false,
          processData: false,
          type: 'POST'
        }).then(() => {
          return this.swal.open({
            icon: 'success',
            title: `${Ember.get(this, 'intl').t("asset.message.fileUpload")}`
          }).then(() => {
            this.set('shownUpload', false);
            this.send('refresh');
          });
        });
      }

    }
  });

  _exports.default = _default;
});