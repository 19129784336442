define("building-management-dashboard/models/supplier", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    address: attr('string'),
    contactNumber: attr('string'),
    contactPerson: attr('string'),
    email: attr('string'),
    name: attr('string'),
    taxNumber: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    building: belongsTo(),
    project: belongsTo()
  });

  _exports.default = _default;
});