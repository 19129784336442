define("building-management-dashboard/pods/components/oneui/select/property/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    properties: Ember.computed('building', function () {
      const building = this.get('building');

      if (Ember.isNone(building)) {
        return [];
      }

      return this.store.query('property', {
        building: building.id,
        limit: false,
        orderBy: 'name'
      });
    }),
    value: Ember.computed('selected.id', 'accounts.[]', {
      async get() {
        return (await this.get('properties')).findBy('id', this.get('selected.id'));
      },

      set(key, value) {
        this.set('selected', value);
        return value;
      }

    })
  });

  _exports.default = _default;
});