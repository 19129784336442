define("building-management-dashboard/pods/components/bill/modal-create/validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    amount: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      gt: 10,
      positive: true
    })],
    billType: (0, _validators.validatePresence)(true),
    chartOfAccount: (0, _validators.validatePresence)(true),
    description: (0, _validators.validatePresence)(true),
    properties: (0, _validators.validatePresence)(true)
  };
  _exports.default = _default;
});