define("building-management-dashboard/pods/components/properties/label-property-contract-type/component", ["exports", "util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: 'label',
    classNames: ['label'],
    classNameBindings: ['_type'],
    _type: Ember.computed('model.propertyContract.type', function () {
      const type = this.get('model.propertyContract.type') === 'owner' ? 'primary' : 'info';
      return `label-${type}`;
    }),
    _label: Ember.computed('model.propertyContract.type', function () {
      const type = this.get('model.propertyContract.type');

      if ((0, _util.isNull)(type)) {
        return null;
      }

      return this.get('intl').t(`propertyContracts.labels.${type}`);
    })
  });

  _exports.default = _default;
});