define("building-management-dashboard/pods/dashboard/my-building/bills/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    pendingBills: Ember.computed.filterBy('model.propertyBills', 'status', 'open'),
    actions: {
      deleted() {
        this.transitionToRoute('dashboard.my-building.bills');
      }

    }
  });

  _exports.default = _default;
});