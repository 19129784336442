define("building-management-dashboard/pods/dashboard/my-building/transactions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ibR5Rxqn",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],null,[[\"header\"],[[31,32,1,[27,[26,2,\"CallHead\"],[]],[\"header.transaction.page\"],null]]],[[\"default\"],[{\"statements\":[[1,0,0,0,[31,0,0,[27,[26,1,\"CallHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],null,null]],null]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"t\",\"layouts/page-default\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/dashboard/my-building/transactions/template.hbs"
    }
  });

  _exports.default = _default;
});