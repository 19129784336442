define("building-management-dashboard/pods/components/admin-dashboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JPUkw9aS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,0,0,0,[31,2,28,[27,[26,0,\"CallHead\"],[]],null,[[\"id\"],[\"sidebar\"]]]],[1,1,0,0,\"\\n\\n\"],[1,0,0,0,[31,49,27,[27,[26,1,\"CallHead\"],[]],null,[[\"elementId\"],[\"header-navbar\"]]]],[1,1,0,0,\"\\n\\n\"],[9,\"main\",true],[12,\"id\",\"main-container\",null],[10],[1,1,0,0,\"\\n  \"],[16,1,null],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[\"admin-dashboard/page-sidebar\",\"admin-dashboard/page-header\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/admin-dashboard/template.hbs"
    }
  });

  _exports.default = _default;
});