define("building-management-dashboard/pods/components/oneui/empty-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wi9yMzC5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"bs-alert\",[[23,\"class\",\"remove-margin\",null]],[[\"@type\",\"@dismissible\"],[\"info\",false]],[[\"default\"],[{\"statements\":[[7,\"fa-icon\",[[23,\"class\",\"push-5-r\",null]],[[\"@icon\",\"@fixedWidth\"],[\"info-circle\",true]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,0,0,0,[31,144,1,[27,[26,0,\"CallHead\"],[]],[\"alert.no-records\"],null]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/empty-list/template.hbs"
    }
  });

  _exports.default = _default;
});