define("building-management-dashboard/pods/components/properties/label-property-contract-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c/e/uB0a",
    "block": "{\"symbols\":[],\"statements\":[[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]]],\"hasEval\":false,\"upvars\":[\"_label\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/properties/label-property-contract-type/template.hbs"
    }
  });

  _exports.default = _default;
});