define("building-management-dashboard/pods/dashboard/users/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    breadCrumb: Ember.computed(function () {
      return {
        title: this.intl.t('model.user', {
          count: 0
        })
      };
    }),

    setupController(controller) {
      controller.set('buildings', this.modelFor('dashboard'));
    },

    titleToken: function () {
      return this.intl.t('header.user.page');
    }
  });

  _exports.default = _default;
});