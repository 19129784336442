define("building-management-dashboard/pods/components/oneui/oneui-layout/sidebar/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EEC4pTpF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"button\",false],[23,\"class\",\"btn btn-link text-gray pull-right hidden-md hidden-lg\",null],[3,0,0,[27,[26,0,\"ModifierHead\"],[]],[[27,[24,0],[]],\"close\"],null],[10],[7,\"fa-icon\",[],[[\"@icon\"],[\"times\"]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[11],[16,1,null]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/oneui-layout/sidebar/header/template.hbs"
    }
  });

  _exports.default = _default;
});