define("building-management-dashboard/pods/components/oneui/modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    backdropClose: false,
    size: 'md'
  });

  _exports.default = _default;
});