define("building-management-dashboard/pods/components/oneui/loading/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    title: Ember.computed(function () {
      return this.intl.t('alert.loading');
    })
  });

  _exports.default = _default;
});