define("building-management-dashboard/pods/dashboard/my-building/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Erq02cgL",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],null,[[\"buildings\",\"myBuilding\"],[[27,[26,3,\"Expression\"],[]],[27,[26,2,\"Expression\"],[]]]],[[\"default\"],[{\"statements\":[[1,0,0,0,[31,0,0,[27,[26,1,\"CallHead\"],[]],[[31,0,0,[27,[26,0,\"CallHead\"],[]],null,null]],null]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"myBuilding\",\"buildings\",\"bm-dashboard\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/dashboard/my-building/template.hbs"
    }
  });

  _exports.default = _default;
});