define("building-management-dashboard/pods/components/oneui/oneui-panel/panel-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XtlUYxQ+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[16,1,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[9,\"h3\",true],[12,\"class\",\"block-title\",null],[10],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"title\",\"if\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/oneui-panel/panel-header/template.hbs"
    }
  });

  _exports.default = _default;
});