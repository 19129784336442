define("building-management-dashboard/constants/locale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    ENGLISH: 'en',
    FRENCH: 'fr',
    DUTCH: 'nl',
    options: Ember.A([{
      title: 'Dutch',
      value: 'nl-nl'
    }, {
      title: 'English',
      value: 'en-us'
    }, {
      title: 'French',
      value: 'fr-fr'
    }])
  };
  _exports.default = _default;
});