define("building-management-dashboard/pods/components/attachment/modal-list-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W7Y0tfwt",
    "block": "{\"symbols\":[\"@open\",\"@attachments\",\"@onDeleted\",\"@onUpload\"],\"statements\":[[7,\"oneui/modal\",[],[[\"@confirmButtonText\",\"@open\",\"@title\",\"@onSubmit\"],[[31,35,1,[27,[26,0,\"CallHead\"],[]],[\"button.close\"],null],[27,[24,1],[]],[31,79,1,[27,[26,0,\"CallHead\"],[]],[\"alert.attachment.summary\"],[[\"count\"],[[27,[24,2],[\"length\"]]]]],[31,148,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[31,156,3,[27,[26,1,\"CallHead\"],[]],[[27,[24,1],[]]],null],false],null]]],[[\"default\"],[{\"statements\":[[7,\"attachment/list-view\",[],[[\"@attachments\",\"@onDeleted\",\"@onUpload\"],[[27,[24,2],[]],[31,240,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[31,248,8,[27,[26,3,\"CallHead\"],[]],[[27,[24,3],[]]],null]],null],[31,283,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[27,[24,4],[]]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"mut\",\"action\",\"optional\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/attachment/modal-list-view/template.hbs"
    }
  });

  _exports.default = _default;
});