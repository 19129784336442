define("building-management-dashboard/pods/dashboard/my-building/property-groups/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: Ember.computed('controller.propertyGroup', function () {
      return {
        title: this.controller.get('propertyGroup.name')
      };
    }),

    setupController(controller, propertyGroup) {
      controller.setProperties({
        propertyGroup
      });
    }

  });

  _exports.default = _default;
});