define("building-management-dashboard/pods/components/oneui/oneui-form/bs-checkbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'label',
    classNames: ['css-input', 'css-checkbox'],
    classNameBindings: ['_checkboxType'],
    type: 'default',
    _checkboxType: Ember.computed('type', function () {
      return `css-checkbox-${Ember.get(this, 'type')}`;
    })
  });

  _exports.default = _default;
});