define("building-management-dashboard/pods/components/bill/table-property-bill/row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    property: Ember.computed.alias('propertyBill.property'),
    transaction: Ember.computed.alias('propertyBill.transaction'),
    accountName: Ember.computed.alias('transaction.creditAccount.name'),
    amount: Ember.computed.alias('propertyBill.amount'),
    name: Ember.computed.alias('property.name'),
    shares: Ember.computed.alias('property.shares'),
    totalShares: Ember.computed.alias('property.building.shares')
  });

  _exports.default = _default;
});