define("building-management-dashboard/abilities/property", ["exports", "ember-can"], function (_exports, _emberCan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend({
    currentUser: Ember.inject.service(),
    user: Ember.computed.alias('currentUser.user'),
    canCreate: Ember.computed.or('user.isAdmin', 'user.isSuperAdmin'),
    canDelete: Ember.computed.or('user.isAdmin', 'user.isSuperAdmin'),
    canEdit: Ember.computed.or('user.isAdmin', 'user.isSuperAdmin')
  });

  _exports.default = _default;
});