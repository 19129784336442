define("building-management-dashboard/pods/components/properties/button-view-property-contract/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    _type: Ember.computed('model.propertyContract.type', function () {
      return this.get('model.propertyContract.type') === 'owner' ? 'primary' : 'info';
    })
  });

  _exports.default = _default;
});