define("building-management-dashboard/pods/components/bill/table-property-bill/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WZPSdOJy",
    "block": "{\"symbols\":[\"@propertyBill\",\"@onPay\",\"@canPay\"],\"statements\":[[9,\"td\",true],[10],[1,0,0,0,[27,[24,0],[\"name\"]]],[11],[9,\"td\",true],[10],[9,\"span\",true],[10],[1,0,0,0,[27,[24,0],[\"shares\"]]],[1,1,0,0,\"/\"],[1,0,0,0,[27,[24,0],[\"totalShares\"]]],[11],[11],[9,\"td\",true],[10],[5,[27,[26,6,\"BlockHead\"],[]],[[31,91,3,[27,[26,4,\"CallHead\"],[]],[[27,[24,0],[\"transaction\",\"isPending\"]]],null]],null,[[\"default\"],[{\"statements\":[[5,[27,[26,6,\"BlockHead\"],[]],[[31,131,3,[27,[26,5,\"CallHead\"],[]],[[27,[24,3],[]],[31,144,3,[27,[26,4,\"CallHead\"],[]],[[27,[24,0],[\"accountName\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[7,\"bs-button\",[],[[\"@size\",\"@type\",\"@onClick\"],[\"xs\",\"success\",[31,216,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[31,224,8,[27,[26,1,\"CallHead\"],[]],[[27,[24,2],[]]],null],[27,[24,1],[]]],null]]],[[\"default\"],[{\"statements\":[[7,\"fa-icon\",[[23,\"class\",\"push-5-r\",null]],[[\"@icon\",\"@fixedWidth\"],[\"money-check\",true]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[1,0,0,0,[31,334,1,[27,[26,3,\"CallHead\"],[]],[\"alert.bill.pay\"],[[\"amount\"],[[27,[24,0],[\"amount\"]]]]]]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[1,0,0,0,[27,[24,0],[\"accountName\"]]],[9,\"small\",true],[12,\"class\",\"text-muted push-5-l\",null],[10],[1,0,0,0,[31,449,11,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[\"transaction\",\"createdAt\"]]],[[\"format\"],[\"md\"]]]],[11]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[11],[9,\"td\",true],[12,\"class\",\"text-right\",null],[10],[1,0,0,0,[31,553,13,[27,[26,7,\"CallHead\"],[]],[[27,[24,0],[\"amount\"]]],[[\"currency\",\"style\"],[\"EUR\",\"currency\"]]]],[11]],\"hasEval\":false,\"upvars\":[\"format-date\",\"optional\",\"action\",\"t\",\"not\",\"and\",\"if\",\"format-number\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/bill/table-property-bill/row/template.hbs"
    }
  });

  _exports.default = _default;
});