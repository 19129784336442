define("building-management-dashboard/pods/components/oneui/pagination/component", ["exports", "building-management-dashboard/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['data-table-pagination', 'row', 'pull-t'],
    label: 'records',
    showText: true,
    currentPage: Ember.computed.alias('model.current_page'),
    total: Ember.computed.alias('model.total'),
    totalPage: Ember.computed.alias('model.total_pages'),
    limits: Ember.computed(function () {
      return _environment.default.APP.pageLimits.map(limit => Ember.Object.create({
        label: limit,
        value: limit
      }));
    }),
    limit: Ember.computed('model.per_page', {
      get() {
        return this.get('limits').findBy('label', this.get('model.per_page'));
      }

    }),
    start: Ember.computed('currentPage', function () {
      const {
        current_page,
        per_page
      } = this.get('model');
      return (current_page - 1) % per_page * per_page + 1;
    }),
    end: Ember.computed('currentPage', function () {
      const {
        current_page,
        per_page,
        count
      } = this.get('model');
      return (current_page - 1) % per_page * per_page + count;
    }),
    actions: {
      selectLimit(limit) {
        this.get('onLimitChange')(limit.get('value'));
      }

    }
  });

  _exports.default = _default;
});