define("building-management-dashboard/pods/components/oneui/card/table/thead/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jpQuZLR4",
    "block": "{\"symbols\":[\"column\",\"@sortedBy\",\"@sortable\",\"@orderBy\",\"@columns\",\"@showActionColumn\"],\"statements\":[[9,\"tr\",true],[10],[5,[27,[26,5,\"BlockHead\"],[]],[[31,0,0,[27,[26,4,\"CallHead\"],[]],[[31,0,0,[27,[26,4,\"CallHead\"],[]],[[27,[24,5],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[1,0,0,0,[31,36,28,[27,[26,1,\"CallHead\"],[]],null,[[\"align\",\"name\",\"orderBy\",\"sortable\",\"sortedBy\",\"title\"],[[27,[24,1],[\"align\"]],[27,[24,1],[\"name\"]],[27,[24,4],[]],[31,128,3,[27,[26,3,\"CallHead\"],[]],[[27,[24,3],[]],[31,143,2,[27,[26,2,\"CallHead\"],[]],[[27,[24,1],[\"sortable\"]],false],null]],null],[27,[24,2],[]],[27,[24,1],[\"title\"]]]]]]],\"parameters\":[1]}]]],[5,[27,[26,6,\"BlockHead\"],[]],[[27,[24,6],[]]],null,[[\"default\"],[{\"statements\":[[1,0,0,0,[31,245,28,[27,[26,1,\"CallHead\"],[]],null,[[\"align\",\"title\"],[\"right\",[31,295,1,[27,[26,0,\"CallHead\"],[]],[\"title.actions\"],null]]]]]],\"parameters\":[]}]]],[11]],\"hasEval\":false,\"upvars\":[\"t\",\"oneui/card/data-table/header\",\"or\",\"and\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/card/table/thead/template.hbs"
    }
  });

  _exports.default = _default;
});