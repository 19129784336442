define("building-management-dashboard/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    RESTSerializer
  } = _emberData.default;

  var _default = RESTSerializer.extend({
    serializeAttribute(snapshot, json, key, attributes) {
      const {
        record
      } = snapshot;
      const attrs = Ember.get(snapshot, 'adapterOptions.attrs');

      if (snapshot.changedAttributes()[key] || record.get('isNew') || attrs && attrs.includes(key)) {
        this._super(snapshot, json, key, attributes);
      }
    },

    serializeIntoHash(hash, type, record, options) {
      Ember.assign(hash, this.serialize(record, options));
    }

  });

  _exports.default = _default;
});