define("building-management-dashboard/pods/components/oneui/login-v1/component", ["exports", "building-management-dashboard/utils/focus-error"], function (_exports, _focusError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      login() {
        this.setProperties({
          error: null,
          isLoading: true
        });
        const model = this.getProperties('identification', 'password');
        this.get('onSubmit')(model).catch(error => {
          this.setProperties({
            error,
            isLoading: false
          });
          (0, _focusError.default)();
        });
      }

    }
  });

  _exports.default = _default;
});