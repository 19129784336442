define("building-management-dashboard/pods/dashboard/my-building/suppliers/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    swalErr: Ember.inject.service(),
    actions: {
      add() {
        this.set('selected', this.store.createRecord('supplier', {
          building: this.building
        }));
      },

      cancel(supplier) {
        supplier.rollbackAttributes();
      },

      delete(supplier) {
        this.swalErr.run(() => supplier.destroyRecord());
      },

      saved() {
        this.send('refresh');
      }

    }
  });

  _exports.default = _default;
});