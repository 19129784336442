define("building-management-dashboard/pods/components/transaction/table-data/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G+z9196m",
    "block": "{\"symbols\":[\"@transaction\"],\"statements\":[[9,\"td\",true],[10],[9,\"small\",true],[12,\"class\",\"text-muted\",null],[10],[1,0,0,0,[31,32,11,[27,[26,1,\"CallHead\"],[]],[[27,[24,1],[\"createdAt\"]]],[[\"format\"],[\"md\"]]]],[11],[11],[9,\"td\",true],[10],[1,0,0,0,[27,[24,1],[\"transactable\",\"property\",\"name\"]]],[11],[9,\"td\",true],[10],[1,0,0,0,[27,[24,0],[\"description\"]]],[11],[9,\"td\",true],[10],[1,0,0,0,[27,[24,0],[\"asset\",\"name\"]]],[11],[9,\"td\",true],[12,\"class\",\"font-w600 text-right\",null],[10],[5,[27,[26,3,\"BlockHead\"],[]],[[31,242,2,[27,[26,2,\"CallHead\"],[]],[[27,[24,1],[\"creditAccount\",\"type\"]],\"asset\"],null]],null,[[\"default\"],[{\"statements\":[[1,0,0,0,[31,289,13,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"amount\"]]],[[\"format\"],[\"EUR\"]]]]],\"parameters\":[]}]]],[11],[9,\"td\",true],[12,\"class\",\"font-w600 text-right\",null],[10],[5,[27,[26,3,\"BlockHead\"],[]],[[31,389,2,[27,[26,2,\"CallHead\"],[]],[[27,[24,1],[\"debitAccount\",\"type\"]],\"asset\"],null]],null,[[\"default\"],[{\"statements\":[[1,0,0,0,[31,435,13,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"amount\"]]],[[\"format\"],[\"EUR\"]]]]],\"parameters\":[]}]]],[11]],\"hasEval\":false,\"upvars\":[\"format-number\",\"format-date\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/transaction/table-data/row/template.hbs"
    }
  });

  _exports.default = _default;
});