define("building-management-dashboard/pods/components/bill/list-view-attachments/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let BillListViewAttachmentsComponent = (_dec = Ember.inject.service('bill'), _dec2 = Ember.computed.alias('args.bill.attachments'), (_class = (_temp = class BillListViewAttachmentsComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "billSvc", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "swalErr", _descriptor3, this);

      _initializerDefineProperty(this, "attachments", _descriptor4, this);
    }

    deleted(bill) {
      bill.reload();
    }

    async upload(file) {
      const data = await this.swalErr.run(() => this.billSvc.addAttachment(this.args.bill, file), () => file.queue.set('files', []));
      this.store.pushPayload(data);
      const attachment = this.store.peekRecord('attachment', data.attachment.id);
      const attachments = await this.attachments;
      attachments.pushObject(attachment);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "billSvc", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "swalErr", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "attachments", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deleted", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "deleted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "upload", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "upload"), _class.prototype)), _class));
  _exports.default = BillListViewAttachmentsComponent;
});