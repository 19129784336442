define("building-management-dashboard/pods/dashboard/my-building/property-groups/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NZdDYKtH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"oneui/page\",[],[[\"@header\"],[[31,23,1,[27,[26,0,\"CallHead\"],[]],[\"header.property-group.page\"],null]]],[[\"default\"],[{\"statements\":[[9,\"div\",true],[12,\"class\",\"row\",null],[10],[9,\"div\",true],[12,\"class\",\"col-md-5\",null],[10],[7,\"property-group/list-data\",[],[[\"@canAdd\",\"@limit\",\"@page\",\"@propertyGroups\",\"@pagination\",\"@onAdd\"],[[31,130,3,[27,[26,1,\"CallHead\"],[]],[\"create property-group\"],null],[27,[26,2,\"AppendSingleId\"],[]],[27,[26,3,\"AppendSingleId\"],[]],[27,[26,4,\"AppendSingleId\"],[]],[27,[26,4,\"AppendSingleId\"],[\"meta\",\"pagination\"]],[31,283,11,[27,[26,7,\"CallHead\"],[]],[[31,296,6,[27,[26,6,\"CallHead\"],[]],[[27,[24,0],[]],\"add\"],null],[31,311,13,[27,[26,5,\"CallHead\"],[]],[\"showCreate\",[27,[24,0],[]]],null]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[11],[9,\"div\",true],[12,\"class\",\"col-md-7\",null],[10],[1,0,0,0,[31,0,0,[27,[26,9,\"CallHead\"],[]],[[31,0,0,[27,[26,8,\"CallHead\"],[]],null,null]],null]],[11],[11]],\"parameters\":[]}]]],[7,\"property-group/modal-entry\",[],[[\"@building\",\"@open\",\"@propertyGroup\",\"@onCancel\",\"@onSaved\"],[[27,[26,10,\"AppendSingleId\"],[]],[27,[26,11,\"AppendSingleId\"],[]],[27,[26,12,\"AppendSingleId\"],[]],[31,547,6,[27,[26,6,\"CallHead\"],[]],[[27,[24,0],[]],\"cancel\"],null],[31,576,6,[27,[26,6,\"CallHead\"],[]],[[27,[24,0],[]],\"saved\"],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"can\",\"limit\",\"page\",\"propertyGroups\",\"toggle-action\",\"action\",\"pipe-action\",\"-outlet\",\"component\",\"building\",\"showCreate\",\"selected\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/dashboard/my-building/property-groups/template.hbs"
    }
  });

  _exports.default = _default;
});