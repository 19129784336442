define("building-management-dashboard/pods/dashboard/buildings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2jBsHcPQ",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],null,[[\"buildings\",\"myBuilding\"],[[27,[26,3,\"Expression\"],[]],[27,[26,3,\"Expression\"],[\"firstObject\"]]]],[[\"default\"],[{\"statements\":[[7,\"oneui/page\",[],[[\"@header\"],[[31,93,1,[27,[26,0,\"CallHead\"],[]],[\"header.building.page\"],null]]],[[\"default\"],[{\"statements\":[[1,0,0,0,[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],null,null]],null]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\",\"buildings\",\"bm-dashboard\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/dashboard/buildings/template.hbs"
    }
  });

  _exports.default = _default;
});