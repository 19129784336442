define("building-management-dashboard/services/ajax", ["exports", "ember-ajax/services/ajax", "building-management-dashboard/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    APP
  } = _environment.default;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    namespace: `${APP.apiNamespace}`,
    host: `${APP.apiHost}`,
    headers: Ember.computed('session.data.authenticated.access_token', {
      get() {
        let headers = {};
        const authToken = Ember.get(this, 'session.data.authenticated.access_token');

        if (authToken) {
          headers['Authorization'] = `Bearer ${authToken}`;
        }

        return headers;
      }

    })
  });

  _exports.default = _default;
});