define("building-management-dashboard/pods/components/admin-dashboard/page-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7hjVDndL",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"pull-right\",null],[10],[1,1,0,0,\"\\n  Crafted with \"],[9,\"i\",true],[12,\"class\",\"fa fa-heart text-city\",null],[10],[11],[1,1,0,0,\" by \"],[9,\"a\",true],[12,\"class\",\"font-w600\",null],[12,\"href\",\"http://goo.gl/vNS3I\",null],[12,\"target\",\"_blank\",null],[10],[1,1,0,0,\"pixelcave\"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\\n\"],[9,\"div\",true],[12,\"class\",\"pull-left\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"a\",true],[12,\"class\",\"font-w600\",null],[12,\"href\",\"http://goo.gl/6LF10W\",null],[12,\"target\",\"_blank\",null],[10],[1,1,0,0,\"OneUI 2.0\"],[11],[1,1,0,0,\" © \"],[9,\"span\",true],[12,\"class\",\"js-year-copy\",null],[10],[11],[1,1,0,0,\"\\n\"],[11]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/admin-dashboard/page-footer/template.hbs"
    }
  });

  _exports.default = _default;
});