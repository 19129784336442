define("building-management-dashboard/pods/components/oneui/oneui-layout/sidebar/nav-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7BCy1Qum",
    "block": "{\"symbols\":[],\"statements\":[[9,\"span\",true],[12,\"class\",\"sidebar-mini-hide\",null],[10],[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]],[11]],\"hasEval\":false,\"upvars\":[\"title\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/oneui-layout/sidebar/nav-header/template.hbs"
    }
  });

  _exports.default = _default;
});