define("building-management-dashboard/pods/components/admin-dashboard/page-sidebar/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'nav',
    actions: {
      close() {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const pageContainer = (0, _jquery.default)('#page-container');

        if (width > 991) {
          pageContainer.removeClass('sidebar-o');
        } else {
          pageContainer.removeClass('sidebar-o-xs');
        }
      }

    }
  });

  _exports.default = _default;
});