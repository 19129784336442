define("building-management-dashboard/pods/components/oneui/card/table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['table-responsive'],
    hover: true,
    striped: false,
    tableClassNames: Ember.computed('hover', 'striped', function () {
      const classNames = [];

      if (this.hover) {
        classNames.push('table-hover');
      }

      if (this.striped) {
        classNames.push('table-striped');
      }

      return classNames;
    })
  });

  _exports.default = _default;
});