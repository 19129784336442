define("building-management-dashboard/models/property-bill", ["exports", "ember-data", "building-management-dashboard/models/transactable"], function (_exports, _emberData, _transactable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;

  var _default = _transactable.default.extend({
    amount: attr('number'),
    metadata: attr(),
    status: attr('string'),
    bill: belongsTo(),
    property: belongsTo(),
    // make payment form
    description: attr('string'),
    paymentAccount: belongsTo('chart-of-account')
  });

  _exports.default = _default;
});