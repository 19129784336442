define("building-management-dashboard/pods/components/bill/modal-list-view-attachments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tTf3NkIM",
    "block": "{\"symbols\":[\"@open\",\"@bill\"],\"statements\":[[7,\"oneui/modal\",[],[[\"@confirmButtonText\",\"@open\",\"@title\",\"@onSubmit\"],[[31,35,1,[27,[26,0,\"CallHead\"],[]],[\"button.close\"],null],[27,[24,1],[]],[31,79,1,[27,[26,0,\"CallHead\"],[]],[\"alert.attachment.summary\"],[[\"count\"],[[27,[24,2],[\"attachments\",\"length\"]]]]],[31,153,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[31,161,3,[27,[26,1,\"CallHead\"],[]],[[27,[24,1],[]]],null],false],null]]],[[\"default\"],[{\"statements\":[[7,\"bill/list-view-attachments\",[],[[\"@bill\"],[[27,[24,2],[]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/bill/modal-list-view-attachments/template.hbs"
    }
  });

  _exports.default = _default;
});