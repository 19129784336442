define("building-management-dashboard/pods/dashboard/projects/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      add() {
        this.set('selected', this.store.createRecord('project'));
      },

      cancel(project) {
        project.rollbackAttributes();
      },

      delete(project) {
        project.destroyRecord();
      },

      saved() {
        return this.send('refresh');
      }

    }
  });

  _exports.default = _default;
});