define("building-management-dashboard/pods/components/oneui/oneui-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fvP+bYca",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[31,9,4,[27,[26,3,\"CallHead\"],[]],null,[[\"header\",\"content\",\"table\"],[[31,22,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/oneui-panel/panel-header\"],[[\"title\",\"type\"],[[27,[26,2,\"Expression\"],[]],[27,[26,1,\"Expression\"],[]]]]],[31,97,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/oneui-panel/panel-content\"],null],[31,149,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/oneui-panel/panel-table\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"type\",\"title\",\"hash\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/oneui-panel/template.hbs"
    }
  });

  _exports.default = _default;
});