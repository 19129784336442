define("building-management-dashboard/pods/components/oneui/card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['block'],
    classNameBindings: ['themed:block-themed'],
    themed: true,
    type: 'primary'
  });

  _exports.default = _default;
});