define("building-management-dashboard/pods/components/oneui/oneui-error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bGVkNlrW",
    "block": "{\"symbols\":[\"@error\"],\"statements\":[[5,[27,[26,0,\"BlockHead\"],[]],[[27,[24,1],[\"status_code\"]]],null,[[\"default\"],[{\"statements\":[[7,\"bs-alert\",[],[[\"@dismissible\",\"@type\"],[false,\"danger\"]],[[\"default\"],[{\"statements\":[[9,\"strong\",true],[12,\"class\",\"push-5-r\",null],[10],[1,1,0,0,\"Error code \"],[1,0,0,0,[27,[24,1],[\"status_code\"]]],[1,1,0,0,\":\"],[11],[1,0,0,0,[27,[24,1],[\"message\"]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/oneui-error/template.hbs"
    }
  });

  _exports.default = _default;
});