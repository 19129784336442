define("building-management-dashboard/adapters/property-bill", ["exports", "building-management-dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForUpdateRecord(id, modelName, snapshot) {
      const {
        adapterOptions
      } = snapshot;

      if (Ember.isPresent(adapterOptions) && adapterOptions.pay) {
        return this._super(...arguments) + '/pay';
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});