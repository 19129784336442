define("building-management-dashboard/pods/components/oneui/oneui-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "udO6lqlb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[31,9,4,[27,[26,1,\"CallHead\"],[]],null,[[\"bsCheckbox\",\"bsInput\",\"bsTextarea\",\"mdInput\",\"holder\"],[[31,30,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/oneui-form/bs-checkbox\"],null],[31,85,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/oneui-form/bs-input\"],null],[31,140,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/oneui-form/bs-textarea\"],null],[31,195,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/oneui-form/md-input\"],null],[31,246,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/oneui-form/input-holder\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/oneui-form/template.hbs"
    }
  });

  _exports.default = _default;
});