define("building-management-dashboard/pods/components/bill/modal-payment/component", ["exports", "building-management-dashboard/pods/components/bill/modal-payment/validation"], function (_exports, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    Validation: _validation.default,
    account: Ember.computed.alias('propertyBill.bill.chartOfAccount'),
    amount: Ember.computed.alias('propertyBill.amount'),
    building: Ember.computed.alias('propertyBill.bill.building'),
    actions: {
      async save(propertyBill) {
        this.set('error', null);
        const {
          onPaid
        } = this.getProperties('onPaid');

        try {
          await propertyBill.save({
            adapterOptions: {
              pay: true
            }
          });

          if (Ember.isPresent(onPaid)) {
            return onPaid(propertyBill);
          }
        } catch (err) {
          this.set('error', err);
        }
      }

    }
  });

  _exports.default = _default;
});