define("building-management-dashboard/pods/components/coa/table-data/parent-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['js-table-sections-header'],
    classNameBindings: ['open'],
    tagName: 'tbody',

    click() {
      if (!Ember.isEmpty(this.account.children)) {
        this.toggleProperty('open');
      }
    }

  });

  _exports.default = _default;
});