define("building-management-dashboard/services/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    async load() {
      if (!this.session.get('isAuthenticated')) {
        return Ember.RSVP.resolve();
      }

      const user = await this.store.queryRecord('user', {
        userinfo: true,
        include: 'roles'
      });
      this.set('user', user);
    }

  });

  _exports.default = _default;
});