define("building-management-dashboard/pods/components/coa/table-data/child-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R2EEDjnA",
    "block": "{\"symbols\":[\"account\",\"@canDelete\",\"@canEdit\",\"@onDelete\",\"@onEdit\",\"@accounts\"],\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[27,[24,6],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[9,\"tr\",true],[10],[9,\"td\",true],[10],[11],[9,\"td\",true],[10],[1,0,0,0,[27,[24,1],[\"code\"]]],[11],[9,\"td\",true],[10],[1,0,0,0,[27,[24,1],[\"name\"]]],[11],[9,\"td\",true],[12,\"class\",\"text-center text-muted\",null],[10],[1,0,0,0,[27,[24,1],[\"type\"]]],[11],[9,\"td\",true],[12,\"class\",\"text-right\",null],[10],[9,\"small\",true],[12,\"class\",\"text-muted\",null],[10],[1,0,0,0,[31,202,11,[27,[26,0,\"CallHead\"],[]],[[27,[24,1],[\"updatedAt\"]]],[[\"format\"],[\"md\"]]]],[11],[11],[9,\"td\",true],[12,\"class\",\"text-right\",null],[10],[7,\"oneui/card/data-table/action\",[],[[\"@item\",\"@showDeleteButton\",\"@showEditButton\",\"@onDelete\",\"@onEdit\"],[[27,[24,1],[]],[27,[24,2],[]],[27,[24,3],[]],[31,405,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],[27,[24,4],[]]],null],[31,434,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],[27,[24,5],[]]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[11],[11]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"format-date\",\"action\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/coa/table-data/child-row/template.hbs"
    }
  });

  _exports.default = _default;
});