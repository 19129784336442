define("building-management-dashboard/pods/dashboard/my-building/property-groups/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z2GmJqRj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"property-group/table-properties\",[],[[\"@canEdit\",\"@canDelete\",\"@propertyGroup\",\"@onEdit\",\"@onDelete\"],[[31,43,3,[27,[26,0,\"CallHead\"],[]],[\"edit property-group\"],null],[31,84,3,[27,[26,0,\"CallHead\"],[]],[\"delete property-group\"],null],[27,[26,1,\"AppendSingleId\"],[]],[31,157,13,[27,[26,2,\"CallHead\"],[]],[\"showEdit\",[27,[24,0],[]]],null],[31,201,13,[27,[26,2,\"CallHead\"],[]],[\"showDelete\",[27,[24,0],[]]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[7,\"property-group/modal-entry\",[],[[\"@building\",\"@open\",\"@propertyGroup\",\"@onCancel\"],[[27,[26,1,\"AppendSingleId\"],[\"building\"]],[27,[26,3,\"AppendSingleId\"],[]],[27,[26,1,\"AppendSingleId\"],[]],[31,396,6,[27,[26,4,\"CallHead\"],[]],[[27,[24,0],[]],\"cancel\"],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[7,\"oneui/swal/confirm-delete\",[],[[\"@show\",\"@text\",\"@onDelete\"],[[27,[26,5,\"AppendSingleId\"],[]],[31,499,1,[27,[26,6,\"CallHead\"],[]],[\"alert.property-group.delete\"],[[\"name\"],[[27,[26,1,\"Expression\"],[\"name\"]]]]],[31,569,6,[27,[26,4,\"CallHead\"],[]],[[27,[24,0],[]],\"delete\",[27,[26,1,\"Expression\"],[]]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"can\",\"propertyGroup\",\"toggle-action\",\"showEdit\",\"action\",\"showDelete\",\"t\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/dashboard/my-building/property-groups/show/template.hbs"
    }
  });

  _exports.default = _default;
});