define("building-management-dashboard/pods/components/bill/list-view-attachments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lVf1QQCW",
    "block": "{\"symbols\":[\"@bill\"],\"statements\":[[7,\"attachment/list-view\",[],[[\"@attachments\",\"@onDeleted\",\"@onUpload\"],[[27,[24,1],[\"attachments\"]],[31,70,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"deleted\",[27,[24,1],[]]],null],[31,107,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"upload\"],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/bill/list-view-attachments/template.hbs"
    }
  });

  _exports.default = _default;
});