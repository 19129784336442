define("building-management-dashboard/pods/dashboard/my-building/transactions/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    selectedAccount: Ember.computed('chartOfAccount', function () {
      if (Ember.isPresent(this.chartOfAccount)) {
        return this.store.find('chart-of-account', this.chartOfAccount);
      }

      return null;
    }),
    selectedProperty: Ember.computed('property', function () {
      if (Ember.isPresent(this.property)) {
        return this.store.find('property', this.property);
      }

      return null;
    })
  });

  _exports.default = _default;
});