define("building-management-dashboard/pods/components/oneui/select/account-type/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.types = [{
        title: this.intl.t('title.asset'),
        value: 'asset'
      }, {
        title: this.intl.t('title.expense'),
        value: 'expense'
      }];
    },

    value: Ember.computed('selected', {
      get() {
        const type = this.get('selected');

        if (typeof type === 'string') {
          return this.types.findBy('value', type);
        }

        return type;
      },

      set(key, type) {
        this.set('selected', Ember.isPresent(type) ? type.value : null);
        return type;
      }

    })
  });

  _exports.default = _default;
});