define("building-management-dashboard/pods/components/supplier/modal-entry/validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    address: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    contactNumber: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 20
    })],
    contactPerson: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    taxNumber: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 50
    })]
  };
  _exports.default = _default;
});