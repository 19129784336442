define("building-management-dashboard/pods/components/oneui/select/properties/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FyYY/jo4",
    "block": "{\"symbols\":[\"property\",\"@onChange\",\"@disabled\"],\"statements\":[[5,[27,[26,6,\"BlockHead\"],[]],null,[[\"allowClear\",\"disabled\",\"noMatchesMessage\",\"options\",\"placeholder\",\"renderInPlace\",\"searchEnabled\",\"searchField\",\"selected\",\"onChange\"],[true,[27,[24,3],[]],[31,78,1,[27,[26,0,\"CallHead\"],[]],[\"alert.no-matches\"],null],[27,[24,0],[\"properties\"]],[31,137,1,[27,[26,0,\"CallHead\"],[]],[\"placeholder.select-properties\"],null],true,true,\"name\",[27,[26,3,\"Expression\"],[]],[31,257,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[31,265,5,[27,[26,5,\"CallHead\"],[]],[[31,272,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[31,280,3,[27,[26,4,\"CallHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null]],null],[31,296,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[31,304,8,[27,[26,1,\"CallHead\"],[]],[[27,[24,2],[]]],null]],null]],null]],null]]],[[\"default\"],[{\"statements\":[[9,\"span\",true],[12,\"class\",\"push-5-r\",null],[10],[1,0,0,0,[27,[24,1],[\"name\"]]],[11],[9,\"small\",true],[12,\"class\",\"text-muted\",null],[10],[1,0,0,0,[31,417,1,[27,[26,0,\"CallHead\"],[]],[\"title.shares\"],null]],[1,1,0,0,\": \"],[1,0,0,0,[27,[24,1],[\"shares\"]]],[11]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"optional\",\"action\",\"selected\",\"mut\",\"queue\",\"power-select-multiple\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/select/properties/template.hbs"
    }
  });

  _exports.default = _default;
});