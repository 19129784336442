define("building-management-dashboard/pods/dashboard/my-building/contacts/contact/invoices/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    breadCrumb: Ember.computed(function () {
      return {
        title: this.get('intl').t('propertyContract.title.other')
      };
    }),

    model() {
      const contact = this.modelFor('dashboard.admin.contacts.contact').get('id');
      return this.get('store').query('property-contract', {
        contact
      });
    },

    setupController(controller, model) {
      controller.set('propertyContracts', model);
    }

  });

  _exports.default = _default;
});