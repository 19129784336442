define("building-management-dashboard/pods/components/contact/table-data/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.columns = [{
        key: 'fullName',
        name: 'first_name',
        sortable: true,
        title: this.intl.t('title.name')
      }, {
        name: 'email',
        sortable: true,
        title: this.intl.t('title.email')
      }, {
        key: 'contactNumber',
        name: 'contact_number',
        sortable: true,
        title: this.intl.t('title.contact-number')
      }, {
        name: 'address',
        sortable: true,
        title: this.intl.t('title.address')
      }, {
        align: 'right',
        key: 'updatedAt',
        name: 'updated_at',
        sortable: true,
        title: this.intl.t('title.last-modified'),
        type: 'date'
      }];
    }

  });

  _exports.default = _default;
});