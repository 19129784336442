define("building-management-dashboard/pods/components/oneui/card/data-table/header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S3nvArik",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[16,1,null]],\"parameters\":[]},{\"statements\":[[1,0,0,0,[27,[26,0,\"AppendSingleId\"],[]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"title\",\"if\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/card/data-table/header/template.hbs"
    }
  });

  _exports.default = _default;
});