define("building-management-dashboard/pods/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    routeAfterAuthentication: 'dashboard',
    currentUser: Ember.inject.service(),
    intl: Ember.inject.service(),
    languageCookie: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.intl.setLocale(this.languageCookie.getLanguage());
    },

    beforeModel() {
      return this._loadCurrentUser();
    },

    sessionAuthenticated() {
      this._super(...arguments);

      this._loadCurrentUser();
    },

    title(tokens) {
      tokens.reverse().push(this.intl.t('title.app'));
      return tokens.join(' | ');
    },

    async _loadCurrentUser() {
      try {
        await this.currentUser.load();
      } catch (ex) {
        this.session.invalidate();
      }
    }

  });

  _exports.default = _default;
});