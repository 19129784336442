define("building-management-dashboard/pods/components/property-group/table-properties/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.columns = [{
        name: 'name',
        title: this.intl.t('title.name')
      }, {
        name: 'shares',
        title: this.intl.t('title.shares')
      }, {
        name: 'contact.fullName',
        title: this.intl.t('model.contact', {
          count: 1
        })
      }];
    }

  });

  _exports.default = _default;
});