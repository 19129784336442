define("building-management-dashboard/pods/dashboard/my-building/transactions/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),
    queryParams: {
      chartOfAccount: {
        refreshModel: true
      },
      dateFrom: {
        refreshModel: true
      },
      dateTo: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      property: {
        refreshModel: true
      }
    },

    model(params) {
      params.building = this.modelFor('dashboard.my-building').get('id');
      params.orderBy = 'created_at';
      params.sortedBy = 'desc';
      params.totalCashFlow = 1;
      return this.store.query('transaction', params);
    },

    setupController(controller, transactions) {
      const building = this.modelFor('dashboard.my-building');
      controller.setProperties({
        building,
        transactions
      });
    },

    titleToken: function () {
      return this.intl.t('header.transaction.page');
    }
  });

  _exports.default = _default;
});