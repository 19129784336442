define("building-management-dashboard/pods/dashboard/my-building/contacts/contact/transactions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LevGcwdm",
    "block": "{\"symbols\":[\"propertyContract\"],\"statements\":[[5,[27,[26,10,\"BlockHead\"],[]],[[31,7,3,[27,[26,9,\"CallHead\"],[]],[[27,[26,4,\"Expression\"],[\"length\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[5,[27,[26,8,\"BlockHead\"],[]],null,[[\"type\",\"dismissible\"],[\"success\",false]],[[\"default\"],[{\"statements\":[[1,0,0,0,[31,86,1,[27,[26,7,\"CallHead\"],[]],[\"propertyContract.message.zero\"],null]]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[9,\"div\",true],[12,\"class\",\"list-group\",null],[10],[5,[27,[26,6,\"BlockHead\"],[]],[[31,0,0,[27,[26,5,\"CallHead\"],[]],[[31,0,0,[27,[26,5,\"CallHead\"],[]],[[27,[26,4,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[5,[27,[26,3,\"BlockHead\"],[]],null,[[\"active\",\"propertyContract\"],[[27,[24,1],[\"hasTransactions\"]],[27,[24,1],[]]]],[[\"default\"],[{\"statements\":[[9,\"div\",true],[12,\"class\",\"panel panel-default\",null],[10],[9,\"div\",true],[12,\"class\",\"panel-body\",null],[10],[1,0,0,0,[31,391,27,[27,[26,2,\"CallHead\"],[]],null,[[\"transactions\"],[[31,433,7,[27,[26,1,\"CallHead\"],[]],[[31,442,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"getTransactions\"],null],[27,[24,1],[]]],null]]]]],[11],[11]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[11]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"compute\",\"contacts/table-transactions\",\"contacts/collapsible-property-contract\",\"propertyContracts\",\"-track-array\",\"each\",\"t\",\"bs-alert\",\"not\",\"if\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/dashboard/my-building/contacts/contact/transactions/template.hbs"
    }
  });

  _exports.default = _default;
});