define("building-management-dashboard/pods/components/oneui/main-dashboard/page-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aPp0Pjv5",
    "block": "{\"symbols\":[],\"statements\":[[9,\"div\",true],[12,\"class\",\"row\",null],[10],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[12,\"class\",\"col-md-6\",null],[12,\"align\",\"right\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"a\",true],[12,\"href\",\"https://www.filefish.be\",null],[12,\"target\",\"_blank\",null],[10],[1,1,0,0,\"\\n      \"],[9,\"img\",true],[12,\"class\",\"img-responsive fish-file-logo\",null],[12,\"width\",\"100\",null],[12,\"height\",\"100\",null],[12,\"src\",\"/assets/img/logo/file-fish.png\",null],[12,\"alt\",\"\",null],[10],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n  \"],[9,\"div\",true],[12,\"class\",\"col-md-6\",null],[10],[1,1,0,0,\"\\n    \"],[9,\"a\",true],[12,\"href\",\"https://neso.io\",null],[12,\"target\",\"_blank\",null],[10],[1,1,0,0,\"\\n      \"],[9,\"img\",true],[12,\"class\",\"img-responsive neso-logo\",null],[12,\"width\",\"100\",null],[12,\"height\",\"100\",null],[12,\"src\",\"/assets/img/logo/neso-logo.svg\",null],[12,\"alt\",\"\",null],[10],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n  \"],[11],[1,1,0,0,\"\\n\"],[11],[1,1,0,0,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/main-dashboard/page-footer/template.hbs"
    }
  });

  _exports.default = _default;
});