define("building-management-dashboard/pods/components/oneui/select/roles/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Baa7gVN+",
    "block": "{\"symbols\":[\"role\"],\"statements\":[[5,[27,[26,10,\"BlockHead\"],[]],null,[[\"allowClear\",\"disabled\",\"noMatchesMessage\",\"options\",\"placeholder\",\"renderInPlace\",\"searchEnabled\",\"searchField\",\"selected\",\"onChange\"],[true,[27,[26,9,\"Expression\"],[]],[31,77,1,[27,[26,6,\"CallHead\"],[]],[\"alert.no-matches\"],null],[31,108,9,[27,[26,8,\"CallHead\"],[]],[\"name\",\"super_admin\",[27,[26,7,\"Expression\"],[]]],null],[31,160,1,[27,[26,6,\"CallHead\"],[]],[\"placeholder.select-roles\"],null],true,true,\"displayName\",[27,[26,3,\"Expression\"],[]],[31,282,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[31,290,5,[27,[26,5,\"CallHead\"],[]],[[31,297,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[31,305,3,[27,[26,4,\"CallHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null]],null],[31,321,6,[27,[26,2,\"CallHead\"],[]],[[27,[24,0],[]],[31,329,8,[27,[26,1,\"CallHead\"],[]],[[27,[26,0,\"Expression\"],[]]],null]],null]],null]],null]]],[[\"default\"],[{\"statements\":[[1,0,0,0,[27,[24,1],[\"displayName\"]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"onChange\",\"optional\",\"action\",\"selected\",\"mut\",\"queue\",\"t\",\"_roles\",\"reject-by\",\"disabled\",\"power-select-multiple\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/select/roles/template.hbs"
    }
  });

  _exports.default = _default;
});