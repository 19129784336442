define("building-management-dashboard/models/building", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    address: attr('string'),
    name: attr('string'),
    shares: attr('number'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    project: belongsTo(),
    properties: hasMany()
  });

  _exports.default = _default;
});