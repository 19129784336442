define("building-management-dashboard/pods/components/oneui/select/bill-account/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    accounts: Ember.computed('building', function () {
      const {
        building
      } = this.getProperties('building');

      if (Ember.isNone(building)) {
        return [];
      }

      return this.store.query('chart-of-account', {
        building: building.get('id'),
        limit: false,
        orderBy: 'name',
        types: ['asset', 'expense']
      });
    }),
    groupedAccounts: Ember.computed('accounts.[]', async function () {
      const accounts = await this.get('accounts');
      return [{
        groupName: this.intl.t('title.expense'),
        options: accounts.filterBy('type', 'expense')
      }, {
        groupName: this.intl.t('title.asset'),
        options: accounts.filterBy('type', 'asset')
      }];
    }),
    value: Ember.computed('selected.id', 'accounts.[]', {
      async get() {
        const id = this.get('selected.id');
        return (await this.get('accounts')).findBy('id', id);
      },

      set(key, account) {
        this.set('selected', account);
        return account;
      }

    }),

    matcher({
      code,
      name
    }, term) {
      return `${name} ${code}`.toLowerCase().indexOf(term);
    }

  });

  _exports.default = _default;
});