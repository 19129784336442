define("building-management-dashboard/pods/components/oneui/lock-v1/component", ["exports", "building-management-dashboard/utils/focus-error"], function (_exports, _focusError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    _password: '',
    actions: {
      unlock() {
        this.setProperties({
          _validationErrors: null,
          _errorMessage: null
        });
        this.get('onSubmit')({
          identification: this.get('user.email'),
          password: this.get('_password')
        }).catch(error => {
          if (error.status_code === 422) {
            this.set('_validationErrors', error.errors);
          } else {
            this.set('_errorMessage', error.message);
          }

          (0, _focusError.default)();
        });
      }

    }
  });

  _exports.default = _default;
});