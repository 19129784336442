define("building-management-dashboard/pods/dashboard/my-building/chart-of-accounts/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      limit: {
        refreshModel: true
      },
      orderBy: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      sortedBy: {
        refreshModel: true
      }
    },

    model(params) {
      return this.store.query('chart-of-account', Ember.assign(params, {
        building: this.modelFor('dashboard.my-building').get('id'),
        noParent: true,
        orderBy: 'code'
      }));
    },

    setupController(controller, accounts) {
      controller.setProperties({
        accounts,
        building: this.modelFor('dashboard.my-building')
      });
    },

    actions: {
      refresh() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});