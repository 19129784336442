define("building-management-dashboard/pods/components/oneui/main-dashboard/page-sidebar/side-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['side-header', 'side-content', 'bg-white-op']
  });

  _exports.default = _default;
});