define("building-management-dashboard/pods/dashboard/my-building/documents/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wvENa2ue",
    "block": "{\"symbols\":[],\"statements\":[[7,\"document/table-data\",[],[[\"@attachments\",\"@building\",\"@canUpload\",\"@canDelete\",\"@orderBy\",\"@parentFolder\",\"@sortedBy\",\"@onSaved\"],[[27,[24,0],[\"attachments\"]],[27,[24,0],[\"building\"]],[31,96,3,[27,[26,0,\"CallHead\"],[]],[\"upload document\"],null],[31,133,3,[27,[26,0,\"CallHead\"],[]],[\"delete document\"],null],[27,[24,0],[\"orderBy\"]],[27,[24,0],[\"parentFolder\"]],[27,[24,0],[\"sortedBy\"]],[31,258,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],\"saved\"],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[7,\"oneui/pagination\",[],[[\"@label\",\"@model\",\"@onLimitChange\",\"@onPageChange\"],[[31,325,1,[27,[26,2,\"CallHead\"],[]],[\"model.document\"],[[\"count\"],[[27,[24,0],[\"attachments\",\"meta\",\"pagination\",\"total\"]]]]],[27,[24,0],[\"attachments\",\"meta\",\"pagination\"]],[31,452,11,[27,[26,4,\"CallHead\"],[]],[[31,465,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],[31,473,3,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[\"limit\"]]],null]],null],[31,491,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],[31,499,3,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[\"page\"]]],null],1],null]],null],[31,535,6,[27,[26,1,\"CallHead\"],[]],[[27,[24,0],[]],[31,543,3,[27,[26,3,\"CallHead\"],[]],[[27,[24,0],[\"page\"]]],null]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"can\",\"action\",\"t\",\"mut\",\"pipe-action\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/dashboard/my-building/documents/index/template.hbs"
    }
  });

  _exports.default = _default;
});