define("building-management-dashboard/pods/components/user/table-data/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.columns = [{
        key: 'fullName',
        name: 'first_name',
        sortable: true,
        title: this.intl.t('title.name')
      }, {
        name: 'email',
        sortable: true,
        title: this.intl.t('title.email')
      }, {
        key: 'project.name',
        name: 'projects|name',
        sortable: true,
        title: this.intl.t('model.project', {
          count: 1
        })
      }, {
        key: user => user.roles.mapBy('displayName').join(', '),
        title: this.intl.t('model.role', {
          count: 0
        })
      }, {
        align: 'right',
        key: 'updatedAt',
        name: 'updated_at',
        sortable: true,
        title: this.intl.t('title.last-modified'),
        type: 'date'
      }];
    }

  });

  _exports.default = _default;
});