define("building-management-dashboard/pods/components/oneui/oneui-layout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WPVmgBSk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[16,1,[[31,9,4,[27,[26,1,\"CallHead\"],[]],null,[[\"footer\",\"header\",\"main\",\"sidebar\"],[[31,22,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/oneui-layout/footer\"],null],[31,69,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/oneui-layout/header\"],null],[31,114,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/oneui-layout/main\"],null],[31,160,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/oneui-layout/sidebar\"],null]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/oneui-layout/template.hbs"
    }
  });

  _exports.default = _default;
});