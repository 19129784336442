define("building-management-dashboard/pods/dashboard/my-building/contacts/contact/invoices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tqNq8HBe",
    "block": "{\"symbols\":[\"propertyContract\"],\"statements\":[[5,[27,[26,1,\"BlockHead\"],[]],[[31,7,3,[27,[26,8,\"CallHead\"],[]],[[27,[26,3,\"Expression\"],[\"length\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[5,[27,[26,7,\"BlockHead\"],[]],null,[[\"type\",\"dismissible\"],[\"success\",false]],[[\"default\"],[{\"statements\":[[1,0,0,0,[31,86,1,[27,[26,6,\"CallHead\"],[]],[\"propertyContract.message.zero\"],null]]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[9,\"div\",true],[12,\"class\",\"list-group\",null],[10],[5,[27,[26,5,\"BlockHead\"],[]],[[31,0,0,[27,[26,4,\"CallHead\"],[]],[[31,0,0,[27,[26,4,\"CallHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null]],null]],null,[[\"default\"],[{\"statements\":[[5,[27,[26,2,\"BlockHead\"],[]],null,[[\"active\",\"propertyContract\"],[[31,265,2,[27,[26,1,\"CallHead\"],[]],[[27,[24,1],[\"invoiceProperties\",\"length\"]],true,false],null],[27,[24,1],[]]]],[[\"default\"],[{\"statements\":[[9,\"div\",true],[12,\"class\",\"panel panel-default\",null],[10],[9,\"div\",true],[12,\"class\",\"panel-body\",null],[10],[9,\"div\",true],[12,\"class\",\"table-responsive\",null],[10],[1,0,0,0,[31,446,33,[27,[26,0,\"CallHead\"],[]],null,[[\"invoiceProperties\"],[[27,[24,1],[\"invoiceProperties\"]]]]]],[11],[11],[11]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[11]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"contacts/table-invoice-properties\",\"if\",\"contacts/collapsible-property-contract\",\"propertyContracts\",\"-track-array\",\"each\",\"t\",\"bs-alert\",\"not\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/dashboard/my-building/contacts/contact/invoices/template.hbs"
    }
  });

  _exports.default = _default;
});