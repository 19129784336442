define("building-management-dashboard/pods/components/document/modal-entry/component", ["exports", "@glimmer/component", "building-management-dashboard/pods/components/document/modal-entry/validation"], function (_exports, _component, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AttachmentModalEntryComponent = (_class = (_temp = class AttachmentModalEntryComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "error", _descriptor, this);

      this.Validation = _validation.default;
    }

    async save(attachment) {
      this.error = null;

      try {
        await attachment.save();

        if (Ember.isPresent(this.args.onSaved)) {
          this.args.onSaved(attachment);
        }
      } catch (error) {
        this.error = error;
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "error", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [Ember._action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  _exports.default = AttachmentModalEntryComponent;
});