define("building-management-dashboard/services/role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    _roles: null,

    getList() {
      if (Ember.isNone(this._roles)) {
        this._roles = this.store.query('role', {
          limit: false
        });
      }

      return this._roles;
    }

  });

  _exports.default = _default;
});