define("building-management-dashboard/pods/components/oneui/oneui-panel/panel-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5kkxxJEW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"table\",true],[12,\"class\",\"table table-hover dataTable\",null],[10],[16,1,[[31,52,4,[27,[26,1,\"CallHead\"],[]],null,[[\"header\"],[[31,65,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/oneui-panel/panel-table/header\"],null]]]]]],[11]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/oneui-panel/panel-table/template.hbs"
    }
  });

  _exports.default = _default;
});