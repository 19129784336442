define("building-management-dashboard/pods/components/properties/button-view-property-contract/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dcgWcuh+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,[27,[26,5,\"BlockHead\"],[]],null,[[\"onClick\",\"size\",\"type\"],[[31,22,6,[27,[26,4,\"CallHead\"],[]],[[27,[24,0],[]],[27,[26,3,\"Expression\"],[]]],null],\"xs\",[27,[26,2,\"Expression\"],[]]]],[[\"default\"],[{\"statements\":[[1,1,0,0,\"  \"],[1,0,0,0,[31,65,7,[27,[26,0,\"CallHead\"],[]],[\"address-book-o\"],null]],[1,1,0,0,\"\\n  \"],[1,0,0,0,[27,[26,1,\"Expression\"],[\"propertyContract\",\"contact\",\"fullName\"]]],[1,1,0,0,\"\\n  \"],[16,1,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fa-icon\",\"model\",\"_type\",\"onClick\",\"action\",\"bs-button\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/properties/button-view-property-contract/template.hbs"
    }
  });

  _exports.default = _default;
});