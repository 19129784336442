define("building-management-dashboard/pods/components/document/modal-entry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fBUN3sk3",
    "block": "{\"symbols\":[\"model\",\"form\",\"@open\",\"@title\",\"@onCancel\",\"@attachment\"],\"statements\":[[7,\"oneui/modal/entry\",[],[[\"@disabled\",\"@error\",\"@open\",\"@size\",\"@title\",\"@onHidden\"],[[27,[24,0],[\"disabled\"]],[27,[24,0],[\"error\"]],[27,[24,3],[]],\"sm\",[31,107,2,[27,[26,4,\"CallHead\"],[]],[[27,[24,4],[]],[31,118,1,[27,[26,1,\"CallHead\"],[]],[\"header.document.create\"],null]],null],[31,160,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],[27,[24,5],[]]],null]]],[[\"default\"],[{\"statements\":[[5,[27,[26,6,\"BlockHead\"],[]],[[31,188,9,[27,[26,5,\"CallHead\"],[]],[[27,[24,6],[]],[27,[24,0],[\"Validation\"]]],null]],null,[[\"default\"],[{\"statements\":[[7,\"bs-form\",[],[[\"@model\",\"@onSubmit\"],[[27,[24,1],[]],[31,276,6,[27,[26,0,\"CallHead\"],[]],[[27,[24,0],[]],\"save\"],null]]],[[\"default\"],[{\"statements\":[[9,\"fieldset\",true],[13,\"disabled\",[27,[24,0],[\"disabled\"]],null],[10],[9,\"button\",true],[12,\"class\",\"hidden\",null],[12,\"type\",\"submit\",null],[10],[1,1,0,0,\"Submit\"],[11],[1,0,0,0,[31,0,0,[27,[26,3,\"CallHead\"],[]],[[31,0,0,[27,[26,2,\"CallHead\"],[]],[[27,[24,2],[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('building-management-dashboard/pods/components/document/modal-entry/template.hbs' @ L1:C393) \"],null]],[[\"label\",\"property\"],[[31,413,1,[27,[26,1,\"CallHead\"],[]],[\"title.title\"],null],\"title\"]]]],[11]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"-assert-implicit-component-helper-argument\",\"component\",\"or\",\"changeset\",\"with\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/document/modal-entry/template.hbs"
    }
  });

  _exports.default = _default;
});