define("building-management-dashboard/templates/components/page-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U5DTTGJA",
    "block": "{\"symbols\":[],\"statements\":[[5,[27,[26,4,\"BlockHead\"],[]],[[27,[26,3,\"Expression\"],[]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"  \"],[9,\"a\",true],[10],[1,0,0,0,[27,[26,2,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"  \"],[9,\"a\",false],[14,\"href\",[32,[[27,[26,0,\"AppendSingleId\"],[]]]],null],[3,0,0,[27,[26,1,\"ModifierHead\"],[]],[[27,[24,0],[]],\"select\"],null],[10],[1,0,0,0,[27,[26,2,\"AppendSingleId\"],[]]],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"url\",\"action\",\"page\",\"isDots\",\"if\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/templates/components/page-item.hbs"
    }
  });

  _exports.default = _default;
});