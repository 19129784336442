define("building-management-dashboard/pods/components/bill/modal-create/component", ["exports", "pretty-bytes", "building-management-dashboard/pods/components/bill/modal-create/validation"], function (_exports, _prettyBytes, _validation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const BILL_TYPE_BUILDING = 'building';
  const BILL_TYPE_PROPERTY_GROUP = 'property-group';

  var _default = Ember.Component.extend({
    BILL_TYPE_BUILDING,
    BILL_TYPE_PROPERTY_GROUP,
    Validation: _validation.default,
    billService: Ember.inject.service('bill'),
    intl: Ember.inject.service(),
    swalErr: Ember.inject.service(),

    async init() {
      this._super(...arguments);

      this.billTypes = Ember.A([{
        title: 'Building',
        value: BILL_TYPE_BUILDING
      }, {
        title: 'Property Group',
        value: BILL_TYPE_PROPERTY_GROUP
      }]);
    },

    fileLabel: Ember.computed('file', function () {
      const file = this.get('file');
      return file ? `${file.name} (${(0, _prettyBytes.default)(file.size)})` : this.intl.t('button.add-attachment');
    }),
    actions: {
      cancel() {
        this.set('file', null);

        if (Ember.isPresent(this.onCancel)) {
          this.onCancel();
        }
      },

      setBillProperties(bill) {
        const properties = bill.get('billType.value') === BILL_TYPE_PROPERTY_GROUP ? this.get('propertyGroup.properties') : this.building.get('properties');
        bill.set('properties', properties);
      },

      async save(bill) {
        this.setProperties({
          disabled: true,
          error: null
        });

        try {
          await bill.save();
          const {
            file,
            paymentAccount,
            onSaved
          } = this.getProperties('file', 'paymentAccount', 'onSaved');

          if (Ember.isPresent(file)) {
            await this.swalErr.run(() => this.billService.addAttachment(bill, file));
          }

          if (Ember.isPresent(onSaved)) {
            return onSaved(paymentAccount, bill);
          }
        } catch (err) {
          this.set('error', err);
        }

        this.set('disabled', false);
      }

    }
  });

  _exports.default = _default;
});