define("building-management-dashboard/translations/fr-fr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "alert": {
      "attachment": {
        "summary": "{count, plural,\n  =0 {pièces jointes}\n  other {({count}) pièces jointes}\n}\n"
      },
      "bill": {
        "delete": "Supprimer cette facture, {name}?",
        "force-delete": "Forcer la suppression ignore les contraintes de transaction.",
        "pay": "Payez {amount, number, EUR}"
      },
      "building": {
        "delete": "Supprimer ce bâtiment, {name}?"
      },
      "coa": {
        "delete": "Supprimer ce compte, {name}?"
      },
      "contact": {
        "delete": "Supprimer ce contact, {name}?"
      },
      "document": {
        "delete": "Delete this document, {name}?"
      },
      "drop-upload": "Déposer pour télécharger",
      "invalid": "Invalide",
      "loading": "S'il vous plaît, attendez...",
      "login": {
        "welcome": "Bienvenue, veuillez vous connecter."
      },
      "n-items": "{count} éléments",
      "no-matches": "Aucun résultat trouvé.",
      "no-records": "Aucun enregistrement retourné.",
      "please-confirm": "Veuillez confirmer",
      "project": {
        "delete": "Supprimer ce projet, {name}?"
      },
      "property": {
        "delete": "Supprimer cette propriété, {name}?"
      },
      "property-group": {
        "delete": "Supprimer ce groupe de propriétés, {name}?"
      },
      "shares": "Partages: {shares}",
      "showing-records": "Affichage de {start} - {end} sur {total} {resource}",
      "supplier": {
        "delete": "Supprimer ce fournisseur, {name}?"
      },
      "total-shares": "Total des actions: {shares}",
      "upload-files": "Téléchargement de {count} fichiers. ({progress}%)",
      "user": {
        "delete": "Supprimer cet utilisateur, {name}?"
      }
    },
    "button": {
      "add": "Ajouter",
      "add-attachment": "Ajouter une pièce jointe",
      "cancel": "Annuler",
      "close": "Fermer",
      "delete": "Supprimer",
      "edit": "Éditer",
      "export-pdf": "Exporter le PDF",
      "filter": "Filtre",
      "force-delete": "Forcer la suppression",
      "mark-paid": "Mark a payé",
      "ok": "D'accord",
      "save": "Enregistrer",
      "upload": "Télécharger",
      "view": "Vue"
    },
    "header": {
      "bill": {
        "create": "Ajouter une nouvelle facture",
        "page": "Gestion des factures",
        "pay": "Paiement de factures"
      },
      "building": {
        "create": "Ajouter un nouveau bâtiment",
        "edit": "Modifier le bâtiment {name}",
        "page": "Gestion des bâtiments"
      },
      "coa": {
        "create": "Ajouter un nouveau compte",
        "edit": "Modifier le compte {name}",
        "page": "Gestion des comptes"
      },
      "contact": {
        "create": "Ajouter un nouveau contact",
        "edit": "Modifier le contact {name}",
        "page": "Gestion des contacts"
      },
      "document": {
        "create": "Ajouter un nouveau dossier",
        "page": "Gestion des documents"
      },
      "invoice": {
        "page": "Gestion des factures"
      },
      "login": {
        "page": "S'identifier"
      },
      "project": {
        "create": "Ajouter un nouveau projet",
        "edit": "Modifier le projet {name}",
        "page": "Gestion de projets"
      },
      "property": {
        "create": "Ajouter une nouvelle propriété",
        "edit": "Modifier la propriété {name}",
        "page": "Gestion des propriétés"
      },
      "property-group": {
        "create": "Ajouter une nouvelle propriété",
        "page": "Gestion des groupes de propriétés"
      },
      "supplier": {
        "create": "Ajouter un nouveau fournisseur",
        "edit": "Modifier le fournisseur {name}",
        "page": "Gestion des fournisseurs"
      },
      "transaction": {
        "page": "Gestion des transactions"
      },
      "user": {
        "create": "Ajouter un nouvel utilisateur",
        "edit": "Modifier l'utilisateur {name}",
        "page": "Gestion des utilisateurs"
      }
    },
    "model": {
      "account": "{count, plural, one {compte} other {comptes}}\n",
      "administrator": "{count, plural, one {administrateur} other {les administrateurs}}\n",
      "attachment": "{count, plural, one {attachement} other {pièces jointes}}\n",
      "bill": "{count, plural, one {facture} other {factures}}\n",
      "building": "{count, plural, one {bâtiment} other {bâtiments}}\n",
      "contact": "{count, plural, one {contact} other {contacts}}\n",
      "document": "{count, plural, =1 {document} other {les documents}}\n",
      "invoice": "{count, plural, one {facture d'achat} other {factures}}\n",
      "project": "{count, plural, one {projet} other {projets}}\n",
      "property": "{count, plural, one {propriété} other {propriétés}}\n",
      "property-bill": "{count, plural, one {facture immobilière} other {factures immobilières}}\n",
      "property-group": "{count, plural, one {groupe de biens} other {groupes de propriétés}}\n",
      "role": "{count, plural, one {rôle} other {rôles}}\n",
      "supplier": "{count, plural, one {fournisseur} other {fournisseurs}}\n",
      "transaction": "{count, plural, one {transaction} other {transactions}}\n",
      "user": "{count, plural, one {utilisateur} other {utilisateurs}}\n"
    },
    "placeholder": {
      "dropzone": "Faites glisser et déposez les fichiers à télécharger",
      "force-delete": "Forcer la suppression",
      "select-account": "Sélectionnez un compte",
      "select-bill-type": "Sélectionnez le type de facture",
      "select-contact": "Choisissez un contact",
      "select-country": "Choisissez un pays",
      "select-project": "Sélectionnez un projet",
      "select-properties": "Sélectionnez les propriétés",
      "select-property": "Sélectionnez une propriété",
      "select-roles": "Sélectionnez les rôles",
      "select-supplier": "Sélectionnez un fournisseur",
      "select-type": "Sélectionnez un type"
    },
    "title": {
      "actions": "Actions",
      "address": "Adresse",
      "admin": "Admin",
      "amount": "Montant",
      "app": "Tableau de bord BM",
      "asset": "Atout",
      "balance": "Équilibre",
      "building": "Bâtiment",
      "capital": "Capitale",
      "code": "Code",
      "contact-number": "Numéro de contact",
      "contact-person": "Contact",
      "country": "Pays",
      "credit": "Crédit",
      "currency": "Devise",
      "date": "Date",
      "debit": "Débit",
      "description": "La description",
      "download": "Télécharger",
      "email": "Email",
      "expense": "Frais",
      "first-name": "Prénom",
      "forgot-password": "mot de passe oublié?",
      "from": "De",
      "income": "Le revenu",
      "languages": "Langages",
      "last-modified": "Dernière modification",
      "last-name": "Nom de famille",
      "limit": "Limite",
      "login": "S'identifier",
      "logout": "Se déconnecter",
      "name": "Nom",
      "owner": "Propriétaire",
      "paid": "Payé",
      "parent": "Parent",
      "password": "Mot de passe",
      "payment": "Paiement",
      "pending": "En attente",
      "postal-code": "code postal",
      "project": "Projet",
      "property-group": "Groupe de propriétés",
      "reference": "Numéro de réference",
      "root": "Root",
      "shares": "Actions",
      "signup": "S'inscrire",
      "size": "Taille",
      "state": "Etat",
      "supplier": "Fournisseur",
      "tax-number": "Numéro d'identification fiscale",
      "title": "Titre",
      "to": "À",
      "total-amount": "Montant total",
      "total-due": "Total dû",
      "total-paid": "Total payé",
      "total-shares": "Actions totales",
      "type": "Type",
      "username": "Nom d'utilisateur",
      "website": "Site Internet"
    },
    "validations": {
      "email": "{description} doit être une adresse e-mail valide",
      "greaterThan": "{description} doit être supérieur à {gt}",
      "phone": "{description} doit être un numéro de téléphone valide",
      "present": "{description} ne peut pas être vide",
      "tooLong": "{description} est trop long (le maximum est de {max} caractères)",
      "url": "{description} doit être une URL valide"
    }
  };
  _exports.default = _default;
});