define("building-management-dashboard/pods/components/oneui/select/roles/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    role: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this._roles = this.role.getList();
    }

  });

  _exports.default = _default;
});