define("building-management-dashboard/pods/components/oneui/main-dashboard/page-sidebar/side-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mlkSIHzg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"ul\",true],[12,\"class\",\"nav-main\",null],[10],[1,1,0,0,\"\\n\"],[5,[27,[26,0,\"BlockHead\"],[]],[[28,[24,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,1,0,0,\"    \"],[16,1,null],[1,1,0,0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[1,1,0,0,\"    \"],[9,\"li\",true],[10],[1,1,0,0,\"\\n      \"],[9,\"a\",true],[12,\"href\",\"\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"i\",true],[12,\"class\",\"si si-speedometer\",null],[10],[11],[1,1,0,0,\"\\n        \"],[9,\"span\",true],[12,\"class\",\"sidebar-mini-hide\",null],[10],[1,1,0,0,\"Dashboard\"],[11],[1,1,0,0,\"\\n      \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\\n    \"],[9,\"li\",true],[12,\"class\",\"nav-main-heading\",null],[10],[1,1,0,0,\"\\n      \"],[9,\"span\",true],[12,\"class\",\"sidebar-mini-hide\",null],[10],[1,1,0,0,\"Super Admin Interface\"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n    \"],[9,\"li\",true],[10],[1,1,0,0,\"\\n      \"],[9,\"a\",true],[12,\"href\",\"\",null],[10],[1,1,0,0,\"\\n        \"],[9,\"i\",true],[12,\"class\",\"si si-grid\",null],[10],[11],[1,1,0,0,\" Projects\\n      \"],[11],[1,1,0,0,\"\\n    \"],[11],[1,1,0,0,\"\\n\"]],\"parameters\":[]}]]],[11]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/main-dashboard/page-sidebar/side-content/template.hbs"
    }
  });

  _exports.default = _default;
});