define("building-management-dashboard/adapters/user", ["exports", "building-management-dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQueryRecord(query) {
      if (query.userinfo) {
        delete query.userinfo;
        return `${this._super(...arguments)}/userinfo`;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});