define("building-management-dashboard/pods/dashboard/my-building/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model({
      building_id: id
    }) {
      return this.modelFor('dashboard').findBy('id', id);
    },

    afterModel(building) {
      const title = building.get('name');
      this.setProperties({
        breadCrumb: {
          title
        },
        titleToken: title
      });
    },

    setupController(controller, myBuilding) {
      controller.setProperties({
        myBuilding,
        buildings: this.modelFor('dashboard')
      });
    }

  });

  _exports.default = _default;
});