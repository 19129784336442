define("building-management-dashboard/adapters/bill", ["exports", "building-management-dashboard/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForDeleteRecord(id, modelName, snapshot) {
      const {
        adapterOptions
      } = snapshot;

      if (Ember.isPresent(adapterOptions) && adapterOptions.force) {
        return `${this._super(...arguments)}?force=1`;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});