define("building-management-dashboard/pods/components/oneui/select/supplier/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    async init() {
      this._super(...arguments);

      this.suppliers = this.store.query('supplier', {
        building: this.get('building.id'),
        limit: false,
        orderBy: 'name'
      });
    }

  });

  _exports.default = _default;
});