define("building-management-dashboard/pods/components/oneui/oneui-form/input-holder/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['hasError'],
    hasError: Ember.computed.bool('errors.length'),
    errorMessages: Ember.computed.map('errors', function (error) {
      return error.message || error;
    })
  });

  _exports.default = _default;
});