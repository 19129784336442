define("building-management-dashboard/models/project", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    address: attr('string'),
    contactNumber: attr('string'),
    country: attr('string'),
    currency: attr('string'),
    email: attr('string'),
    name: attr('string'),
    postalCode: attr('string'),
    state: attr('string'),
    town: attr('string'),
    website: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date')
  });

  _exports.default = _default;
});