define("building-management-dashboard/pods/dashboard/my-building/documents/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xd5jNcJ1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"oneui/page\",[],[[\"@header\"],[[31,23,1,[27,[26,0,\"CallHead\"],[]],[\"header.document.page\"],null]]],[[\"default\"],[{\"statements\":[[1,0,0,0,[31,0,0,[27,[26,2,\"CallHead\"],[]],[[31,0,0,[27,[26,1,\"CallHead\"],[]],null,null]],null]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/dashboard/my-building/documents/template.hbs"
    }
  });

  _exports.default = _default;
});