define("building-management-dashboard/pods/components/bill/modal-payment/validation", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    paymentAccount: (0, _validators.validatePresence)(true)
  };
  _exports.default = _default;
});