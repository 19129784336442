define("building-management-dashboard/pods/components/oneui/oneui-layout/sidebar/body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3ELjOtSv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[9,\"ul\",true],[12,\"class\",\"nav-main\",null],[10],[16,1,[[31,30,4,[27,[26,1,\"CallHead\"],[]],null,[[\"header\",\"item\"],[[31,43,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/oneui-layout/sidebar/nav-header\"],null],[31,100,9,[27,[26,0,\"CallHead\"],[]],[\"oneui/oneui-layout/sidebar/nav-item\"],null]]]]]],[11]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "building-management-dashboard/pods/components/oneui/oneui-layout/sidebar/body/template.hbs"
    }
  });

  _exports.default = _default;
});